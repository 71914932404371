import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/interceptor';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    patientDashboard: {},
    patient: {},
    patientList : [],
    meta : {}
  };


  const slice = createSlice({
    name: 'centerUser',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      addUserSuccess(state){
        state.isLoading = false;
      },

      getUserSuccess(state,action){
        state.isLoading = false;
        state.patientList = action.payload;
        state.patient = {}
      },
      
      getUserByIdSuccess(state,action){
        state.isLoading = false;
        state.patient = action.payload;
      },
      
      getMeta(state,action){
        state.meta = action.payload;
      },

      getDashboardSuccess(state,action){
        state.isLoading = false;
        state.patientDashboard = action.payload;
      }

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------

  export function addCenterUser(data){
    return async () => {
      dispatch(slice.actions.startLoading());
      try{
        const response = await axios.post('/director/user',data);
        dispatch(slice.actions.addUserSuccess(response.data));
        return { status: true }
      }
      catch(e){
        dispatch(slice.actions.hasError(e));
        return { status: false, payload: { error: 'Error' } };
      }
    }
  };
  export function updateCenterUser(id,data){
    return async () => {
      dispatch(slice.actions.startLoading());
      try{
        const response = await axios.put(`/director/patient/${id}`,data);
        dispatch(slice.actions.addUserSuccess(response.data));
        return { status: true }
      }
      catch(e){
        dispatch(slice.actions.hasError(e));
        return { status: false, payload: { error: 'Error' } };
      }
    }
  };
  
  export function deleteCenterUser(id){
    return async () => {
      dispatch(slice.actions.startLoading());
      try{
        await axios.delete(`/director/patient/${id}`);
        return { status: true }
      }
      catch(e){
        dispatch(slice.actions.hasError(e));
        return { status: false, payload: { error: 'Error' } };
      }
    }
  };

  export function getPatient(page,row) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/director/clinic?roles=user&page=${page}&take=${row}`);
        dispatch(slice.actions.getUserSuccess(response.data.data.items[0].users));
        dispatch(slice.actions.getMeta(response.data.data.meta));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };

  export function getPatientById(id,page,row) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/director/clinic?roles=user&patientId=${id}&page=${page}&take=${row}`);
        dispatch(slice.actions.getUserByIdSuccess(response.data.data.items[0].users[0]));
        dispatch(slice.actions.getMeta(response.data.data.meta));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };

  export function getPatientDashboard() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/patient/get-patient-dashboard`);
        dispatch(slice.actions.getDashboardSuccess(response.data.data));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };
