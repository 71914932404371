import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/interceptor';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    center: {},
    centerList: [],
    meta: {}
  };


  const slice = createSlice({
    name: 'programAdmin',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      addCenterSuccess(state){
        state.isLoading = false;
      },
    
      getCenterSuccess(state, action) {
        state.isLoading = false;
        state.centerList = action.payload;
        state.center = {};
      },

      getCenterByIdSuccess(state, action) {
        state.isLoading = false;
        state.center = action.payload;
      },

      getMeta(state,action){
        state.meta = action.payload;
      }

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  export function addCenter(data){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/program-admin/create-center', data);
        dispatch(slice.actions.addCenterSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function updateCenter(id,data){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/program-admin/organization/${id}/center`, data);
        dispatch(slice.actions.addCenterSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function updateCenterStatus(id,status){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/program-admin/organization/${id}/center/status?status=${status}`);
        dispatch(slice.actions.addCenterSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function addCenterDirector(data){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/program-admin/create-center-director', data);
        dispatch(slice.actions.addCenterSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function getCenter(page,pageSize){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/program-admin/center?page=${page}&take=${pageSize}`);
        dispatch(slice.actions.getCenterSuccess(response.data.data.items));
        dispatch(slice.actions.getMeta(response.data.data.meta));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function getCenterById(id){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/program-admin/center?centerId=${id}`);
        dispatch(slice.actions.getCenterByIdSuccess(response.data.data.items[0]));
        dispatch(slice.actions.getMeta(response.data.data.meta));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function deleteCenter(id){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.delete(`/program-admin/center/${id}`);
        dispatch(slice.actions.addCenterSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function deleteDirector(id){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.delete(`/program-admin/director/${id}`);
        dispatch(slice.actions.addCenterSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function publishSurveyCenter(data){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(`/program-admin/publish-survey`,data);
        dispatch(slice.actions.addCenterSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function unPublishSurveyCenter(data){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(`/program-admin/unpublish-survey`,data);
        dispatch(slice.actions.addCenterSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }  

  export function getPaPublishedSurveyByCenter(surveyId){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/program-admin/organization-survey?surveyId=${surveyId}`);
        dispatch(slice.actions.getCenterSuccess(response.data.data.items));
        dispatch(slice.actions.getMeta(response.data.data.meta));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    }
  };