export const compareAndAddProperties = (source, target) => {
    const excludedProperties = ['_id', 'status', 'type', 'updatedAt'];

    Object.keys(source).forEach(key => {
      if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
        if (!target[key]) {
          target[key] = {};
        }
        compareAndAddProperties(source[key], target[key]);
      } else if (!(key in target)) {
        if (source[key] === true) {
          target[key] = false;
        } else if (typeof source[key] === 'string' && source[key].length > 3 && !excludedProperties.includes(key)) {
          target[key] = '';
        }
      }
    });
  
    return target;
  }