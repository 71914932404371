import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/interceptor';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    permission: {},
    permissionList : [],
    customRole: {},
    customRoleList : []
  };


  const slice = createSlice({
    name: 'userPermission',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      addPermissionSuccess(state){
        state.isLoading = false;
      },

      getPermissionSuccess(state,action){
        state.isLoading = false;
        state.permissionList = action.payload;
      },

      getCustomeRoleSuccess(state,action){
        state.isLoading = false;
        state.customRoleList = action.payload;
        state.customRole = {};
      },

      getCustomeRoleByIdSuccess(state,action){
        state.isLoading = false;
        state.customRole = action.payload;
      }

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------

  export function getPermissionList() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/saas/permission');
        dispatch(slice.actions.getPermissionSuccess(response.data.data));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };

  export function addCustomRole(data){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/saas/custom-role',data);
        dispatch(slice.actions.addPermissionSuccess(response.data));
        return {status: true}
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
        return {status: false}
      }
    };
  }

  export function updateCustomRole(id,data){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/saas/custom-role/${id}`,data);
        dispatch(slice.actions.addPermissionSuccess(response.data));
        return {status: true}
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
        return {status: false}
      }
    };
  }

  export function getCustomRole() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/saas/CustomRole');
        dispatch(slice.actions.getCustomeRoleSuccess(response.data.data.items));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };

  export function getCustomRoleById(id) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/saas/CustomRole?customRoleId=${id}`);
        dispatch(slice.actions.getCustomeRoleByIdSuccess(response.data.data.items[0]));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };