import React,{ useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import { setLicenseKey } from "survey-core";
import { ReactElementFactory } from "survey-react-ui";
import "../styles/surveyjs.css";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import PublishTab from './survey/PublishTab';
import { useDispatch, useSelector } from "../redux/store";
import { getPaSurveyById } from "../redux/slices/survey";

setLicenseKey(
    "OTgyZmU3MDUtMmJmZC00MTk5LTg5OWQtMzllZmYyY2VmY2U3OzE9MjAyNS0wNi0xMywyPTIwMjUtMDYtMTMsND0yMDI1LTA2LTEz"
);


ReactElementFactory.Instance.registerElement(
    "publish-tab-component",
    (props) => {
        return React.createElement(PublishTab, props);
    }
);

const StafSurvey = () => {
    const dispatch = useDispatch();
    const { survey } = useSelector((state)=> state.survey);
    const params = useParams();
    const creator = useMemo(() => {
        const options = {
            showTranslationTab: false,
            showJSONEditorTab: false,
            showLogicTab: false,
            isAutoSave: false,
            showDesignerTab : false
        };
        return new SurveyCreator(options);
    }, []);
    
    const surveyTemplatesPlugin = {
        activate: () => {
            // here we have to consume Api from the backend
            // to get the survey with organisations
        },
        deactivate: () => {
            return true;
        }
    };
    creator.addPluginTab(
        "publish-tab-component",
        surveyTemplatesPlugin,
        "Publish",
        "publish-tab-component",
        4
      );
    useEffect(() => {
        (async () => {
            await dispatch(getPaSurveyById(params.surveyId));
            // if (surveyAction.data === null) {
            //     navigate("/404")
            // }
            if (typeof survey === 'object') {
                creator.JSON = survey
            } else {
                creator.text = survey
            }
        })()
    }, [creator, params.surveyId])

    return (
        <div style={{ height: "100%" }}>
            {creator && <SurveyCreatorComponent creator={creator} />}
        </div>
    );
};

export default StafSurvey;