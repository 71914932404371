export const CONSTANT = {
    USER_ROLE : {
        SAAS : 'so',
        PROGRAM_ADMIN : 'pa',
        Director : 'director',
        Staff : 'staff',
        Patient : 'patient',
        Clinician : 'clinician',
        Super_Admin : 'super_admin'
    },

    CONVERT_ROLE: {
        so : 'Super Admin',
        pa : 'Program Admin',
        director: 'Director',
        staff: 'Staff',
        patient: 'Center User',
        clinician: 'Clinician',
        super_admin: 'Super Admin'
    }
}