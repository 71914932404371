import axios from 'axios';
import { startLoading, stopLoading } from '../redux/slices/loader';
import { store } from '../redux/store';
import * as toaster from './toaster';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:3000",
});

let requestCount = 0;

const incrementRequestCount = () => {
  requestCount += 1;
  store.dispatch(startLoading());
};

const decrementRequestCount = () => {
  requestCount -= 1;
  if (requestCount === 0) {
    store.dispatch(stopLoading());
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    incrementRequestCount();
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    decrementRequestCount();
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    decrementRequestCount();
    if(response.data.status === false){
      toaster.error(response.data.message || "Error");
    }
    return response
  },
  (error) => {
    decrementRequestCount();
    console.log("axios",error);
    toaster.error(error.response.data.message || error.response.data.message.message || error.response.data.message.error || error.response.status);
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
)

export default axiosInstance;