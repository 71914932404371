import React from 'react'
import { useParams } from 'react-router-dom'
import { Card, Col, Container, Row, Button } from 'react-bootstrap'
import AddOrganizationForm from '../components/form/AddOrganizationForm'

const AddOrganization = () => {
    const { name } = useParams();
  return (
    <>
   <Container> 
    <Row className='justify-content-center'>
        <Col md={10}>
            <Card className=' shadow-sm px-5 pt-5 pb-3 ' border='0'>
                <Row>
                    <Col>
                        <h2 className='text-secondary'>Add Organization</h2>
                    </Col>
                    <Col>
                        <small className="text-secondary" style={{ fontWeight: '300', float:"right" }}>
                            License Type : <small style={{fontWeight:'500'}}>{name === "program-admin" ? "Program Admin" : "Clinic"}</small>
                        </small>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col>
                        <AddOrganizationForm orgType={name === "program-admin" ? "Program Admin" : "Clinic"} />
                    </Col>
                </Row>
            </Card>  
        </Col>
    </Row>
    </Container>
   </>
  )
}

export default AddOrganization
