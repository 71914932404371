import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    numberOfRequest: 0
  };


  const slice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },

      stopLoading(state) {
        state.isLoading = false;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      }
  
    },
  });
  
  // Reducer
  export default slice.reducer;

  export function startLoading() {
    return async () => {
      dispatch(slice.actions.startLoading());
    };
  };

  export function stopLoading() {
    return async () => {
      dispatch(slice.actions.stopLoading());
    };
  };