import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import emojiImg from '../../assets/images/emoji.png';

const UploadFile = () => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <Form className="radio-tabs">
        <Row className='mt-4'>
          <Col xs={12}>
            <div className="shortTxtInput">
              <input type="text" placeholder="Enter instructions for the respondent (Optional)" />
            </div>
          </Col>
          <Col xs={12}>
            <div className="uploadFileWrp d-flex align-items-center">
                <div className='txtwrp me-4'>
                    <span>Allowable file types</span>
                </div>
                <div className='inputCheck me-4 d-flex align-items-center'>
                    <input type="checkbox" />
                    <span>Document</span>
                </div>
                <div className='inputCheck d-flex align-items-center'>
                    <input type="checkbox" />
                    <span>Image</span>
                </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className='uploadFileTxt'>
                <p>When an invalid file type is uploaded, display this error message.</p>
                <input type="file" />
                <div className='mt-2'>
                    <span>Only PDF, DOC, DOCX, PNG, JPG, JPEG, GIF files are supported.</span>
                </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UploadFile;
