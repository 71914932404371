import React, { useEffect, useMemo, lazy, Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import { surveyLocalization, setLicenseKey, Serializer, ComponentCollection } from "survey-core";
import { localization } from "survey-creator-core";
import { ReactElementFactory } from "survey-react-ui";
import "../styles/surveyjs.css";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { Converter } from "showdown";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "../redux/store";
import { CONSTANT } from "../utils/constant";
import { getDirectorSurveyById, getPaSurveyById, getSurveyById } from "../redux/slices/survey";
import { saveSurvey, updateSurveySettings } from "../utils/apiSurvey";
import { getSurveyStatsById } from "../redux/slices/surveyStats";
import { compareAndAddProperties } from "../utils";

const PublishTab = lazy(() => import('./survey/PublishTab'));
const StatisticsTab = lazy(() => import("./survey/StatisticsTab"));
const DataVisualizerTab = lazy(() => import("./survey/DataVisualizerTab"));
const TableTab = lazy(() => import("./survey/TableTab"));

setLicenseKey(
    "OTgyZmU3MDUtMmJmZC00MTk5LTg5OWQtMzllZmYyY2VmY2U3OzE9MjAyNS0wNi0xMywyPTIwMjUtMDYtMTMsND0yMDI1LTA2LTEz"
);

surveyLocalization.supportedLocales = ["en", "de", "es", "fr"];
localization.getLocale("").ed.designer = "Create Survey";

Serializer.addProperty("question", {
    name: "hiddenDescription",
    category: "general",
    type: "text",
    default: "normal"
});

ComponentCollection.Instance.add({
    name: "hear_voice",
    title: "Hear voice",
    elementsJSON: [
        {
            type: "imagepicker", name: "hearvoice", title: "hear voice",
            "choices": [
                {
                    "value": "true",
                    "imageLink": "https://i.ibb.co/zmw7cTh/speaker.png"
                }
            ],
            "multiSelect": true,
            "imageHeight": 50,
            "imageWidth": 50,
            "imageFit": "cover"
        },
    ],
    iconName: "icon-preview"
});

export const saveSurveySettings = async (surveyJson) => {
    let savedSurvey = null;
    try {
        savedSurvey = await saveSurvey(surveyJson);
        console.log("Survey saved:", surveyJson);
    } catch (error) {
        console.error("Failed to save survey:", error);
    }
    return savedSurvey;
};

ReactElementFactory.Instance.registerElement(
    "publish-tab-component",
    (props) => {
        return React.createElement(PublishTab, props);
    }
);

ReactElementFactory.Instance.registerElement(
    "stats-tab-component",
    (props) => {
        return React.createElement(StatisticsTab, props);
    }
);
ReactElementFactory.Instance.registerElement(
    "dataVisualizer-tab-component",
    (props) => {
        return React.createElement(DataVisualizerTab, props);
    }
);
ReactElementFactory.Instance.registerElement(
    "table-tab-component",
    (props) => {
        return React.createElement(TableTab, props);
    }
);

const NewSurvey = (props) => {
    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { survey } = useSelector((state) => state.survey);

    const user = JSON.parse(localStorage.getItem('user'));

    const creator = useMemo(() => {
        const options = {
            showTranslationTab: false,
            showJSONEditorTab: false,
            showLogicTab: user.role === CONSTANT.USER_ROLE.SAAS,
            isAutoSave: false,
            showThemeTab: user.role === CONSTANT.USER_ROLE.SAAS,
            showDesignerTab: user.role === CONSTANT.USER_ROLE.SAAS,
            questionTypes: [
                "radiogroup",
                "rating",
                "checkbox",
                "dropdown",
                "multidropdown",
                "comment",
                "boolean",
                "ranking",
                "text",
                "html",
                "image",
                "panel",
                "tagbox"
            ]
        };
        return new SurveyCreator(options);
    }, []);
    const converter = new Converter();
    creator.onSurveyInstanceCreated.add((sender, options) => {
        if (options.reason === 'test') {
            options.survey.onTextMarkdown.add((survey, options) => {
                let str = converter.makeHtml(options.text);
                str = str.substring(3);
                str = str.substring(0, str.length - 4);
                options.html = str;
            });
        }
    });
    creator.saveSurveyFunc = async (saveNo, callback) => {
        const results = await updateSurveySettings(params.surveyId, compareAndAddProperties(survey, creator.JSON));
        console.log(results.message === "request entity too large");
        if (results.message === "request entity too large") {
            toast.error("Image size should not exceed 100KB");
        }
        callback(saveNo, true);
    };
    const publishTemplatesPlugin = {
        activate: () => {
            // here we have to consume Api from the backend
            // to get the survey with organisations
        },
        deactivate: () => true
    };
    const statsTemplatesPlugin = {
        activate: () => {
            // here we have to consume Api from the backend
            // to get the survey with organisations
        },
        deactivate: () => true
    };

    useEffect(() => {
        if (user.role === CONSTANT.USER_ROLE.SAAS) {
            dispatch(getSurveyById(params.surveyId));
            dispatch(getSurveyStatsById(params.surveyId));
        }
        if (user.role === CONSTANT.USER_ROLE.PROGRAM_ADMIN) {
            dispatch(getPaSurveyById(params.surveyId));
        }
        if (user.role === CONSTANT.USER_ROLE.Director) {
            dispatch(getDirectorSurveyById(params.surveyId));
        }
    }, [dispatch])

    useEffect(() => {

        if (user.role === CONSTANT.USER_ROLE.Director) {
            creator.addPluginTab(
                "publish-tab-component",
                publishTemplatesPlugin,
                "Share",
                "publish-tab-component",
                4
            );
        }
        else{
            creator.addPluginTab(
                "publish-tab-component",
                publishTemplatesPlugin,
                "Publish",
                "publish-tab-component",
                4
            );
        }


        if (user.role === CONSTANT.USER_ROLE.SAAS) {
            creator.addPluginTab(
                "dataVisualizer-tab-component",
                statsTemplatesPlugin,
                "Data Visualizer",
                "dataVisualizer-tab-component",
                6
            );
        }

        if (user.role === CONSTANT.USER_ROLE.SAAS || user.role === CONSTANT.USER_ROLE.PROGRAM_ADMIN || user.role === CONSTANT.USER_ROLE.Director) {
            creator.addPluginTab(
                "stats-tab-component",
                statsTemplatesPlugin,
                "Statistics",
                "stats-tab-component",
                5
            );

            creator.addPluginTab(
                "table-tab-component",
                statsTemplatesPlugin,
                "Responses",
                "table-tab-component",
                7
            );
        }

    }, [creator]);
    useEffect(() => {
        if (survey === null) {
            navigate("/404");
        }

        creator.JSON = survey;
    }, [creator, params.surveyId, survey]);

    return (
        <div style={{ height: "100%" }}>
            {creator && (
                <Suspense fallback={<div>Loading...</div>}>
                    <SurveyCreatorComponent creator={creator} />
                </Suspense>
            )}
        </div>
    );
};

export default NewSurvey;
