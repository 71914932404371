import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
import { useEffect, useState } from "react";
  import { Line } from "react-chartjs-2";
import { useSelector } from "../../redux/store";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  
  
  const DashboardLineChart = () => {
    const { saasDashboardChartData } = useSelector((state)=> state.organization);

  const months = ["","Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

  const [dailyChart, setDailyChart] = useState([]);
  const [header, setHeader] = useState([]);
  const [lineData, setLineData] = useState([]);

  useEffect(()=>{
    if(saasDashboardChartData){
      setDailyChart(saasDashboardChartData.dailySurveySubmissions);
    }
  },[saasDashboardChartData]);

  useEffect(()=>{
    if(dailyChart && dailyChart.length > 0){
      setHeader([]);
      setLineData([]);
      dailyChart.forEach(ele=>{
        const head = `${ele.day} - ${months[ele.month]} - ${ele.year}`;
        setHeader(oldhead=> [...oldhead, head]);
        setLineData(oldData=> [...oldData, ele.count]);
      });
    }
  },[dailyChart]);
  
  const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  
  const options = {
    plugins: {
      legend: {
        position: "top",
        style:{display:'none'}
      },
    },
  };
  
  const data = {
    labels: header,
    datasets: [
      {
        label: 'Survey Completed',
        data: lineData,
        backgroundColor: "#1973E9",
        borderColor: "#1973E9",
      },
    ],
  };

    return (
      <div style={{ width: '100%', height:230 , color:'white'}}  className='dashboard-chart rounded-3 shadow-sm'>
        <Line options={options} data={data} />
      </div>
    );
  };
  
  export default DashboardLineChart;