import React, {useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "../../redux/store";

function DashboardBarChart() {

  const { saasDashboardChartData } = useSelector((state)=> state.organization);

  const months = ["","Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

  const [monthlyChart, setMonthlyChart] = useState([]);
  const [header, setHeader] = useState([]);
  const [data, setData] = useState([]);

  const [transformedData, setTransformedData] = useState([]);

  useEffect(()=>{
    if(saasDashboardChartData){
      setMonthlyChart(saasDashboardChartData.monthlyRegistrations);
    }
  },[saasDashboardChartData]);

  useEffect(() => {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      let completeData;
      let existingMonths;

      if(monthlyChart && monthlyChart.length > 0){
        completeData = [...monthlyChart];
        existingMonths = new Set(monthlyChart.map(item => `${item.year}-${item.month}`));
      }
      else{
        completeData = [];
        existingMonths = new Set([]);
      }
      
      let monthCounter = currentMonth;
      let yearCounter = currentYear;

        while (completeData.length < 7) {
          const monthKey = `${yearCounter}-${monthCounter}`;
          if (!existingMonths.has(monthKey)) {
            completeData.push({ year: yearCounter, month: monthCounter, count: 0 });
            existingMonths.add(monthKey);
          }
          monthCounter-=1;
          if (monthCounter < 1) {
            monthCounter = 12;
            yearCounter-=1;
          }
        }

      // Sort by year and month to maintain order
      completeData.sort((a, b) => (a.year - b.year) * 12 + (a.month - b.month));
      const final = completeData.slice(-7);
      setTransformedData(final);
  }, [monthlyChart]);

  useEffect(()=>{
    setHeader([]);
    setData([]);
    if(transformedData && transformedData.length > 0){
      console.log(transformedData);
      transformedData.forEach(ele=>{
        const head = `${months[ele.month]} ${ele.year}`;
        setHeader(oldhead=> [...oldhead, head]);
        setData(oldData=> [...oldData, ele.count]);
      });
    }
  },[transformedData]);


//   const [socialName, setsocialName]= useState([]);
//   const [socialValue, setSocialValue]= useState([]);

//   useEffect( ()=>{

//     const socialMedianame=[];
//     const socialMedivalue=[];

//     const getSocialrecord= async()=>{
//       const dataReq= await fetch("http://localhost/reactgraphtutorial/socialmedia");
//       const dataRes= await dataReq.json();
//       //console.log(dataRes);

//       for(let i=0; i<dataRes.length; i++)
//       {
//         socialMedianame.push(dataRes[i].socialmedia_title);
//         socialMedivalue.push(dataRes[i].socialmedia_subscriber);

//       }
//       setsocialName(socialMedianame);
//       setSocialValue(socialMedivalue);
//  }
//   getSocialrecord();

//   },[]);
  
  return (
    <>
      {header && <div className="container px-0 mx-0 rounded-3 shadow-sm" >
        <Chart
          type="bar"
          style={{width:'100%'}}
          height={220}
          series={[
            {
              name: "Patient Registration",
              data,
            },
          ]}
          options={{
            colors:"#1973e9",

            xaxis: {
              categories: header,
              style: { fontSize: "13" },
            },

            yaxis: {
                labels: {
                  formatter: (val) => {
                  return `${val}`;
                  },
                style: { fontSize: "13" },
              },
            },

            dataLabels: {
              formatter: (val) => {
                return `${val}`;
              },
              style: {
                colors: ["transparent"],
                fontSize: 15,
              },
            },

            plotOptions: {
                bar: {
                  borderRadius: 3,
                },
              },

              grid : {
                xaxis: {
                  lines: {
                    show: false
                  },
                }
              }
          }}
        />
      </div>}
    </>
  );
}

export default DashboardBarChart;