import React, { useEffect, useState } from 'react';
import { Col, Container, Row, } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {Button, TablePagination} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from '../../redux/store';
import ConfirmModal from '../../components/modal/ConfirmModal';
import { getDirectorPublishedSurvey, getPublishedSurveyOrganization, publishSurvey, publishSurveyDirector, unPublishSurvey, unPublishSurveyDirector } from '../../redux/slices/survey';
import { CONSTANT } from '../../utils/constant';
import { getPaPublishedSurveyByCenter, publishSurveyCenter, unPublishSurveyCenter } from '../../redux/slices/programAdmin';
import { success } from '../../utils/toaster';
import { getSurveyStatsById } from '../../redux/slices/surveyStats';

  const PublishTab = () => {
  const { surveyId } = useParams();
  const [idSurvey,setIdSurvey] = useState('');
  const [surveyLink, setSurveyLink] = useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const [openShareModal, setOpenShareModal] = React.useState(false);
  const [organizationRow,setOrganizationRow] = React.useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const dispatch = useDispatch();
  const { organizationList, meta:metaOrg } = useSelector((state)=> state.survey);
  const { centerList, meta:metaCenter } = useSelector((state)=> state.programAdmin);

  const user = JSON.parse(localStorage.getItem('user'));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(20);
  const [meta,setMeta] = useState({});

//   useEffect(()=>{
//     console.log(metaOrg || metaCenter);
//     setMeta(metaOrg || metaCenter);
//     console.log(meta);
//     setPage(meta?.currentPage);
//     setRowsPerPage(meta?.itemsPerPage);
//     setTotalCount(meta?.totalItems);
//   },[metaOrg,metaCenter]);

  const handleChangePage = (event, newPage) => {
    const p = newPage + 1;
    setPage(newPage);
    if(user.role === CONSTANT.USER_ROLE.SAAS){
        dispatch(getPublishedSurveyOrganization(idSurvey,p,rowsPerPage));
    }
    else if(user.role === CONSTANT.USER_ROLE.PROGRAM_ADMIN){
        dispatch(getPaPublishedSurveyByCenter(idSurvey,p,rowsPerPage));
    }
    else if(user.role === CONSTANT.USER_ROLE.Director){
        dispatch(getDirectorPublishedSurvey(idSurvey,p,rowsPerPage));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    const p = 1;
    setRowsPerPage(event.target.value);
    if(user.role === CONSTANT.USER_ROLE.SAAS){
        dispatch(getPublishedSurveyOrganization(idSurvey,p,event.target.value));
    }
    else if(user.role === CONSTANT.USER_ROLE.PROGRAM_ADMIN){
        dispatch(getPaPublishedSurveyByCenter(idSurvey,p,event.target.value));
    }
    else if(user.role === CONSTANT.USER_ROLE.Director){
        dispatch(getDirectorPublishedSurvey(idSurvey,p,event.target.value));
    }
  };

  useEffect(()=>{
    setIdSurvey(surveyId);
  },[surveyId]);

  useEffect(() => {
    if(idSurvey !== ''){
        if(user.role === CONSTANT.USER_ROLE.SAAS){
            dispatch(getPublishedSurveyOrganization(idSurvey,page,rowsPerPage));
        }
        else if(user.role === CONSTANT.USER_ROLE.PROGRAM_ADMIN){
            dispatch(getPaPublishedSurveyByCenter(idSurvey,page,rowsPerPage));
        }
        else if(user.role === CONSTANT.USER_ROLE.Director){
            dispatch(getDirectorPublishedSurvey(idSurvey,page,rowsPerPage));
        }
    }
  }, [idSurvey]);

  useEffect(()=>{
    if(user.role === CONSTANT.USER_ROLE.SAAS){
        setOrganizationRow(organizationList?.filter(x=>!x.surveyLink));
        setSurveyLink(organizationList?.find(x=>x.surveyLink)?.surveyLink);
        setTotalCount(metaOrg.totalItems);
    }
    if(user.role === CONSTANT.USER_ROLE.PROGRAM_ADMIN){
        setOrganizationRow(centerList?.filter(x=>!x.surveyLink));
        setSurveyLink(centerList?.find(x=>x.surveyLink)?.surveyLink);
        setTotalCount(metaCenter.totalItems);
    }
    if(user.role === CONSTANT.USER_ROLE.Director){
        setOrganizationRow(organizationList?.filter(x=>!x.surveyLink));
        setSurveyLink(organizationList?.find(x=>x.surveyLink)?.surveyLink);
        setTotalCount(metaOrg.totalItems);
    }
  },[dispatch,organizationList,centerList]);

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectSingleRow = (id) => {
    setSelectedRows([id]);
  }

  const handleSelectAll = () => {
    if (selectedRows.length === organizationRow.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(organizationRow.map((data) => data.id));
    }
  };

  const handlePublish = async () => {
    let data;

    if(user.role === CONSTANT.USER_ROLE.SAAS){
        data = {
            surveyId : idSurvey,
            organizationIds: selectedRows
        };
        await dispatch(publishSurvey(data));
        await dispatch(getPublishedSurveyOrganization(idSurvey));
    }
    else if(user.role === CONSTANT.USER_ROLE.PROGRAM_ADMIN){
        data = {
            surveyId : idSurvey,
            centerIds: selectedRows
        };
        await dispatch(publishSurveyCenter(data));
        await dispatch(getPaPublishedSurveyByCenter(idSurvey));
    }
    else if(user.role === CONSTANT.USER_ROLE.Director){
        data = {
            surveyId : idSurvey,
            userIds: selectedRows
        };
        await dispatch(publishSurveyDirector(data));
        await dispatch(getDirectorPublishedSurvey(idSurvey));
    }

    console.log(data);
    setOpenShareModal(false);
    setSelectedRows([]);
  }

  const handleUnPublish = async () => {
    let data = {};

    if(user.role === CONSTANT.USER_ROLE.SAAS){
        data = {
            surveyId : idSurvey,
            organizationIds: selectedRows
        };
        await dispatch(unPublishSurvey(data));
        await dispatch(getPublishedSurveyOrganization(idSurvey));
    }
    else if(user.role === CONSTANT.USER_ROLE.PROGRAM_ADMIN){
        data = {
            surveyId : idSurvey,
            centerIds: selectedRows
        };
        await dispatch(unPublishSurveyCenter(data));
        await dispatch(getPaPublishedSurveyByCenter(idSurvey));
    }
    else if(user.role === CONSTANT.USER_ROLE.Director){
        data = {
            surveyId : idSurvey,
            userIds: selectedRows
        };
        await dispatch(unPublishSurveyDirector(data));
        await dispatch(getDirectorPublishedSurvey(idSurvey));
    }

    console.log(data);
    setOpenModal(false);
    setSelectedRows([]);
  }

  return (
    <Container style={{overflow:'scroll'}}>
    <Row style={{margin: "20px 0"}}>
        <Col>
            <div>
                <h3>{user.role === CONSTANT.USER_ROLE.Director ? 'Share':'Publish'}</h3>
                <p>Select {user.role === CONSTANT.USER_ROLE.Director ? 'patient':'organization'} to share this survey</p>
            </div>
        </Col>
        <Col>
            <Button
                type="button" 
                variant="contained"
                onClick={()=>{if(selectedRows.length>0){setOpenShareModal(true)}}}
                style={{
                    background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                    border: 'none',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                    fontSize: '13px',
                    float: 'right',
                    padding: '8px 20px'}}>
                {user.role === CONSTANT.USER_ROLE.Director ? 'Share':'Publish'}
            </Button>
            <Button
                type="button" 
                variant="contained"
                onClick={()=>{if(selectedRows.length>0){setOpenModal(true)}}}
                style={{
                    background: '#6A6A6A', 
                    border:'none', 
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                    fontSize: '13px',
                    marginRight: '1rem',
                    float: 'right',
                    padding: '8px 20px'}}>
                {user.role === CONSTANT.USER_ROLE.Director ? 'Unshare':'Unpublish'}                    
            </Button> 
            <button type="button" disabled={surveyLink === ''} onClick={() => {navigator.clipboard.writeText(surveyLink); success("Survey link copied!")}} className="copyBtn float-end me-4">
                <span>
                <ContentCopyIcon />
                </span>{' '}
                Copy Link
            </button>
        </Col>
    </Row>

            <Table striped="rows" className="clinic-table">
                <thead>
                    <tr style={{backgroundColor:"#f2f2f2"}}>
                        <th>
                        <input
                            type="checkbox"
                            checked={selectedRows.length === organizationRow.length}
                            onChange={handleSelectAll}
                        />
                        </th>
                        {user.role === CONSTANT.USER_ROLE.Director ? (
                            <>
                            <th>User Id</th>
                            <th className="text-center">User Name</th>
                            </>
                        )
                        :
                        (
                            <>
                            <th>Organization</th>
                            <th className="text-center">License Type</th>
                            <th>Location</th>
                            </>
                        )}
                        <th>Action</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody style={{height:'100%', }}>
                {organizationRow && organizationRow.map((data) => (
                    <tr key={data.id}>
                        <td>
                            <input
                            type="checkbox"
                            checked={selectedRows.includes(data.id)}
                            onChange={() => handleSelectRow(data.id)}
                            />
                        </td>
                        {user.role === CONSTANT.USER_ROLE.Director ? (
                            <>
                            <td>{data.uniqueId}</td>
                            <td className="text-center">{data.name}</td>
                            </>
                        )
                        :
                        (
                            <>
                            <td>{data.name}</td>
                            <td className="text-center">{data.type === 'admin' ? "Program Admin" : data.type}</td>
                            <td>
                                {data.address ? (
                                <>
                                    {data.address.address1 || ""}
                                    <br />
                                    {data.address.city || ""}, {data.address.zip || ""}
                                </>
                                ) : ""}
                            </td>
                            </>
                        )}
                        <td>{data.isPublished ?
                            <Button
                                type="button" 
                                variant="contained"
                                onClick={()=>{handleSelectSingleRow(data.id);setOpenModal(true);}}
                                style={{
                                    background: '#6A6A6A', 
                                    border:'none', 
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                                    fontSize: '13px'}}>
                                {user.role === CONSTANT.USER_ROLE.Director ? 'Unshare':'Unpublish'}                    
                            </Button> :
                            <Button
                                type="button" 
                                variant="contained"
                                onClick={()=>{handleSelectSingleRow(data.id);setOpenShareModal(true)}}
                                style={{
                                    background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                                    border: 'none',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                                    fontSize: '13px',
                                    padding: '6px 25px'}}>
                                {user.role === CONSTANT.USER_ROLE.Director ? 'Share':'Publish'}
                            </Button>}</td>
                        <td>{user.role === CONSTANT.USER_ROLE.Director ? <>{data.isPublished ? "Shared" : "Not Shared"}</> : <>{data.isPublished ? "Published" : "Not Published"}</>}</td>
                    </tr>
                ))}
                </tbody>
                <ConfirmModal
                    dltModalTxt={`Are you sure you want to share survey with the selected center${selectedRows.length > 1 ? "s?" : "?"}`}
                    show={openShareModal}
                    buttonName={"Share"}
                    action={handlePublish}
                    onHide={() => setOpenShareModal(false)}
                />
                <ConfirmModal
                    dltModalTxt={`Are you sure you want to unshare survey with the selected center${selectedRows.length > 1 ? "s?" : "?"}`}
                    show={openModal}
                    buttonName={"Unshare"}
                    action={handleUnPublish}
                    onHide={() => setOpenModal(false)}
                />
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            
    </Container>
  );
};

export default PublishTab;
