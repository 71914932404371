import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Row, Col, Modal } from 'react-bootstrap';
import { CardMedia, Button, TextField, InputAdornment } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FaUser } from 'react-icons/fa';
import EmailIcon from '@mui/icons-material/Email';
import { useDispatch, useSelector } from '../../redux/store';
import { addOrganizationAdmin } from '../../redux/slices/organization';
import { addCenterDirector, getCenterById } from '../../redux/slices/programAdmin';

const SendCenterDirectorInvitationModal = (props) => {
  const { id } = useParams();
  const { center } = useSelector((state)=> state.programAdmin);
  const dispatch = useDispatch();

  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    note: '',
    centerId: id
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await dispatch(addCenterDirector(data));
    await dispatch(getCenterById(id));
    props.onHide();
  };

  return (
    <Modal {...props} className="adminInviteModal" size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-4">
        <Row>
          <Col md={9}>
            <div className="modalHeading">
              <h2>Send invitation</h2>
              <p>
                Assign a Director to <b>{center.name}</b>
              </p>
            </div>
          </Col>
          <Col md={3}>
            <div className="crossBtn">
              <button type="button" onClick={props.onHide}>
                <CloseIcon />
              </button>
            </div>
          </Col>
        </Row>
        <div className="modalForm">
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <TextField
                  fullWidth
                  name="firstName"
                  variant="outlined"
                  placeholder='First Name'
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaUser />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Col>
              <Col md={6}>
                <TextField
                  fullWidth
                  name="lastName"
                  variant="outlined"
                  placeholder='Last Name'
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaUser />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Col>
              <Col md={12} className="mt-3">
                <TextField
                  fullWidth
                  name="email"
                  variant="outlined"
                  placeholder='Email'
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Col>
              {/* <Col md={12} className="mt-3">
                <TextField
                  fullWidth
                  name="note"
                  variant="outlined"
                  placeholder='Write invitation message (optional)'
                  onChange={handleChange}
                  maxRows={4}
                />
              </Col> */}
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <Button
                  type="submit"
                  style={{
                    background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                    padding: '6px 25px',
                    border: 'none',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                    color: '#fff',
                    width: '100%',
                  }}
                >
                  Invite
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SendCenterDirectorInvitationModal;
