import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { TextField, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch } from '../../../redux/store';
import { addOtp } from '../../../redux/slices/resetPassword';

function OTPForm() {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const inputRefs = useRef([]);

  const handleChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== '' && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (event) => {
    const paste = event.clipboardData.getData('text');
    if (/^[0-9]{6}$/.test(paste)) {
      const newOtp = paste.split('');
      setOtp(newOtp);
      newOtp.forEach((value, index) => {
        inputRefs.current[index].value = value;
      });
      inputRefs.current[5].focus();
      event.preventDefault();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const otpValue = otp.join('');
    console.log(otpValue);
    dispatch(addOtp(otpValue));
  };

  return (
    <>
        <Typography variant="h5" component="h2" align="center" gutterBottom>
            Account Confirmation
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
            Please enter the 6-digit verification code sent to your registered email address
        </Typography>
        <Form onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="space-between" mt={4} mb={4}>
            {otp.map((data, index) => (
            <TextField
                key={index}
                variant="outlined"
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                inputProps={{
                maxLength: 1,
                style: { textAlign: 'center', fontSize: '24px' },
                }}
                inputRef={(el) => {inputRefs.current[index] = el}}
                style={{ width: '50px' }}
            />
            ))}
        </Box>
        
            <LoadingButton fullWidth size="large" type="submit" variant="contained">
            Next
            </LoadingButton>
        </Form>
    </>
  );
}

export default OTPForm;
