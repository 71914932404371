import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/interceptor';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    email : '',
    otp: '',
    password : '',
    hash: ''
  };


  const slice = createSlice({
    name: 'resetPassword',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },

      addEmail(state,action){
        state.email = action.payload;
      },

      addOtp(state,action){
        state.otp = action.payload;
        console.log("action",action.payload);
      },

      addPassword(state,action){
        state.password = action.payload;
        console.log("action",action.payload);
      },

      addHash(state,action){
        state.hash = action.payload;
      },

      successResetPassword(state){
        state.isLoading = false;
      },

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  export function resetPassword(data){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      console.log("data",data);
      dispatch(slice.actions.addEmail(data));
      try {
        const response = await axios.post('/user/resetPassword', {email: data});
        dispatch(slice.actions.successResetPassword(response.data));
        return response.data;
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function verifyOTP(){
    return async (dispatch,getState) => {
      const resetData = getState().resetPassword;
      const data = {
        email: resetData.email,
        otp: resetData.otp,
        hash: resetData.hash,
        password: resetData.password
      }
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/user/verify-OTP', data);
        dispatch(slice.actions.successResetPassword(response.data));
        return response.data;
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function addOtp(otp){
    return async () => {
      dispatch(slice.actions.addOtp(otp));
    }
  }

  export function addEmail(email){
    return async () => {
      dispatch(slice.actions.addEmail(email));
    }
  }

  export function addPassword(password){
    return async () => {
      dispatch(slice.actions.addPassword(password));
    }
  }
  
  export function addHash(hash){
    return async () => {
      dispatch(slice.actions.addHash(hash));
    }
  }