import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import SurveyTabsData from '../../components/Tabs/SurveyTabsData';


const SurveyTabs = () => (
    <div>
        <Container>
            <Row>
                <Col>
                    <SurveyTabsData />
                </Col>
            </Row>
        </Container>
    </div>
  );

export default SurveyTabs
