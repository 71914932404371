import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Image } from 'react-bootstrap';
import { Container, Typography } from '@mui/material';
import { LoginForm } from '../sections/auth/login';
import MapsLogo from '../assets/images/MAPS_logo.svg';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  textAlign: 'center'
}));



export default function LoginPage() {
  return (
    <>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Image style={{
              width: "266px",
              height: "54px",
              display: 'block',
              margin: '0 auto'
            }} src={MapsLogo} alt='logo maps' />
            <Typography style={{ marginBottom: '40px' }} variant="h4" gutterBottom>
              Log in to dashboard
            </Typography>
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
