import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Box, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle } from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import * as toaster from '../../../utils/toaster';
import { useDispatch } from '../../../redux/store';
import { changePassword } from '../../../redux/slices/user';

// ----------------------------------------------------------------------

export default function ChangePasswordForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors }, watch } = useForm();

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    digit: false,
    letter: false,
  });

  const handlePasswordChange = (e) => {
    const pass = e.target.value;
    setPasswordCriteria({
      length: pass.length >= 8,
      digit: /\d/.test(pass),
      letter: /[a-zA-Z]/.test(pass),
    });
  };

  const onSubmit = async (data) => {
    console.log(data);
    const {oldPassword, newPassword, confirmPassword} = data;
    setLoading(true);

    if (!newPassword || !confirmPassword) {
      setLoading(false);
      return;
    }

    if(newPassword !== confirmPassword){
      setLoading(false);
      toaster.error('Password does not match');
      return;
    }

    if(oldPassword === newPassword){
      setLoading(false);
      toaster.error('New password cannot be same as old password');
      return;
    }

    try {
      const response = await dispatch(changePassword({oldPassword,newPassword}))
      if (response.data.status) {
        toaster.success("Password Changed! Login Again");
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/');
      }
      else {
        toaster.error('Error');
      }
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };
  return (
    <Container className="mt-5" maxWidth='sm'>
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={6}>
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>

      <TextField
          name="oldPassword"
          label="Old Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('oldPassword', { 
            required: 'Password is required',
          })}
          error={!!errors.oldPassword}
          helperText={errors.oldPassword ? errors.oldPassword.message : ''}
        />

        <TextField
          name="newPassword"
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('newPassword', { 
            required: 'Password is required', 
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters long'
            },
            pattern: {
              value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
              message: 'Password must contain at least one letter and one number'
            }
          })}
          error={!!errors.newPassword}
          helperText={errors.newPassword ? errors.newPassword.message : ''}
          onChange={handlePasswordChange}
        />

        <TextField
          name="confirmPassword"
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('confirmPassword', { 
            required: 'Password is required',
            validate: {message: (val) => {
              if(watch('newPassword') !== val){
                return "Password does not match"
              }
              return true;
            }}
          })}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
        />

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              {passwordCriteria.length ? <CheckCircle color="success" /> : <CancelIcon color="error" />}
              <Typography variant="body2" sx={{ ml: 1 }}>
                Has at least 8 characters?
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              {passwordCriteria.digit ? <CheckCircle color="success" /> : <CancelIcon color="error" />}
              <Typography variant="body2" sx={{ ml: 1 }}>
                Has one digit?
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              {passwordCriteria.letter ? <CheckCircle color="success" /> : <CancelIcon color="error" />}
              <Typography variant="body2" sx={{ ml: 1 }}>
                Has one letter?
              </Typography>
            </Box>
          </Box>

        <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained">
          Change Password
        </LoadingButton>
      </Stack>
    </Box>
    </Col></Row></Container>
  );
}
