import { useEffect, useState } from 'react';
import { Col, Container, Row, Card, Modal, Button, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FaLock, FaLockOpen, FaPen, FaTrash, FaUser, FaUserEdit } from 'react-icons/fa';
import { TableSortLabel } from '@mui/material';
import ToggleButton from 'react-toggle-button';
import DeleteModal from '../modal/DeleteModal';
import { useDispatch, useSelector } from '../../redux/store';
import { getCustomRole } from '../../redux/slices/userPermission';

const UserPermissionTable = () => {
  const dispatch = useDispatch();

  const { customRoleList } = useSelector((state)=>state.userPermission);

  const [value, setValue] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const handleToggle = () => {
    setValue(!value);
  };

  useEffect(()=>{
    dispatch(getCustomRole());
  },[dispatch]);

  const [order,setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = [...customRoleList].sort((a, b) => {
    if (orderBy) {
      return order === 'asc'
        ? a[orderBy] < b[orderBy] ? -1 : 1
        : a[orderBy] > b[orderBy] ? -1 : 1;
    }
    return 0;
  });

  return (
    <div>
      <Table striped="rows" className="clinic-table user-list-table">
        <thead>
          <tr className="">
          <th><TableSortLabel
              active={orderBy === 'name'}
              direction={orderBy === 'name' ? order : 'asc'}
              onClick={() => handleRequestSort('name')}>
              Name
            </TableSortLabel></th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {sortedData && sortedData.map(customRole=>(
            <tr>
              <td>{customRole.name}</td>

              <td className="text-center d-flex justify-content-around" style={{ right: '10px' }}>
                <Link to={`/dashboard/UserPermissionsEdit/${customRole.id}`}>
                  <div>
                    <FaUser
                      className="bg-primary text-light p-2  rounded-circle ms-2"
                      type="button"
                      style={{
                        width: '29px',
                        height: '29px',
                        background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                      }}
                    />
                    &nbsp;
                    <span className="text-secondary" style={{ fontSize: '12px' }}>
                      Manage Permission
                    </span>{' '}
                  </div>
                </Link>
                <div>
                  <button
                    type="button"
                    onClick={() => setModalShow(true)}
                    style={{ border: 'none', backgroundColor: 'transparent', padding: '0' }}
                  >
                    <FaTrash
                      type="button"
                      className="text-light p-2 rounded-circle bg-secondary ms-2"
                      style={{ width: '29px', height: '29px' }}
                    />
                    &nbsp;
                    <span className="text-secondary" style={{ fontSize: '12px' }}>
                      Delete
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          ))}
          
        </tbody>
      </Table>
      <DeleteModal
        show={modalShow}
        dltModalTxt={'Are you sure you want to Delete the Role'}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default UserPermissionTable;
