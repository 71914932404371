import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Row, Col, Image } from 'react-bootstrap';
import { Container, Box, Typography } from '@mui/material';

import MapsLogo from '../assets/images/MAPS_logo.svg';
import CreateAccountForm from '../sections/auth/login/CreateAccountForm';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

// ----------------------------------------------------------------------

export default function CreateAccountPage() {

    return (
        <>
            <Helmet>
                <title> MAPS | Measurement Assisted Practice System </title>
            </Helmet>

            <StyledRoot>
                <Container className="mt-5" maxWidth='sm'>
                    <Row className="justify-content-md-center">
                        <Col>
                            <Box textAlign="center" mb={4}>
                                <Image style={{
                                            width: "266px",
                                            height: "54px",
                                            display: 'block',
                                            margin: '0 auto'
                                        }} src={MapsLogo} alt='logo maps' />
                                <Typography component="h1" variant="h5">
                                    Create Account
                                </Typography>
                                <CreateAccountForm/>
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </StyledRoot>
        </>
    );
}
