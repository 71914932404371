import React from 'react';
import { Col, Container, Row, Card, Modal, Button, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FaPen, FaTrash, FaUser } from 'react-icons/fa';
import DeleteModal from '../modal/DeleteModal';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered style={{ zIndex: '9999999' }}>
      <Modal.Header closeButton print className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-secondary">Test Survay </span>{' '}
          <span className="px-3 py-1 text-white ms-4" style={{ background: '#07DC4A', fontSize: '15px' }}>
            Survay Submiteded
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>jj</Modal.Body>
    </Modal>
  );
}

const ClinicTable = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  return (
    <>
      <Table striped="rows" className="clinic-table">
        <thead>
          <tr>
            <th>Clinics Name</th>
            <th className="text-center">Directors</th>
            <th>Location</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>RC Rob</td>
            <td className="text-center">
              <span
                className="rounded-circle"
                style={{
                  background: '#ECECEC',
                  color: '#ACACAC',
                  fontSize: '12px',
                  padding: '6px 7px',
                }}
              >
                <FaUser />
              </span>
              <br />
              <small>Assign</small>
            </td>
            <td>
              Street 21,
              <br /> Boston, 560025
            </td>
            <td>Active</td>
            <td>
              <Link to="/dashboard/EditClinic" style={{ textDecoration: 'none', color: '#000' }}>
                <div
                style={{
                  background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                  padding: '0px 5px 2px',
                  borderRadius: '50%',
                  textAlign: 'center',
                  float: 'left',
                }}>
                  <FaPen style={{fill: '#fff', fontSize: '11px'}}/>
                </div>
                &nbsp; Edit
              </Link>
              <button
                type="button"
                onClick={() => setOpenDeleteModal(true)}
                style={{ border: 'none', backgroundColor: 'transparent', padding: '0' }}
              >
                  <div
                  className='bg-secondary'
                  style={{
                    padding: '0px 5px 2px',
                    borderRadius: '50%',
                    textAlign: 'center',
                    float: 'left',
                  }}>
                    <FaTrash style={{fill: '#fff', fontSize: '11px'}}/>
                  </div>
                &nbsp; Delete
              </button>
            </td>
          </tr>
          <tr>
            <td>Arm</td>
            <td className="text-center pt-3">
              <span
                className="bg-primary rounded-circle text-light"
                style={{
                  background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  fontSize: '12px',
                  padding: '6px 5px',
                }}
              >
                KC
              </span>
              <br />
              <small>Dr Kelly Collins</small>
            </td>
            <td>
              151 Merrimac Street, Boston,
              <br /> Massachusetts 021214
              <br />
              (617) 643 - 4699
            </td>
            <td>Active</td>
            <td>
              <Link to="/dashboard/EditClinic" style={{ textDecoration: 'none', color: '#000' }}>
                <FaPen
                  className="bg-primary text-light p-2  rounded-circle"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  }}
                />{' '}
                &nbsp; Edit
              </Link>
              <FaTrash
                type="button"
                className="text-light p-2 rounded-circle bg-secondary ms-3"
                style={{ width: '29px', height: '29px' }}
              />{' '}
              &nbsp; Delete
            </td>
          </tr>
          <tr>
            <td>West End Clinic</td>
            <td className="text-center pt-4">
              <span
                className="bg-primary rounded-circle text-light mb-1"
                style={{
                  background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  fontSize: '12px',
                  padding: '6px 5px',
                }}
              >
                RP
              </span>{' '}
              <br />
              <small>Dr Ruth Parker</small>
            </td>
            <td>
              16 Blossom, Street Boston,
              <br /> Massachusetts 021214
              <br />
              (617) 724 - 7792
            </td>
            <td>Access Disabled</td>
            <td>
              <Link to="/dashboard/WestEndClinic" style={{ textDecoration: 'none', color: '#000' }}>
                <FaPen
                  className="bg-primary text-light p-2  rounded-circle"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  }}
                />{' '}
                &nbsp; Edit
              </Link>
              <FaTrash
                type="button"
                className="text-light p-2 rounded-circle bg-secondary ms-3"
                style={{ width: '29px', height: '29px' }}
              />{' '}
              &nbsp; Delete
            </td>
          </tr>

          <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
          <DeleteModal dltModalTxt={"Are you sure you want to remove the access"} show={openDeleteModal} onHide={() => setOpenDeleteModal(false)} />
        </tbody>
      </Table>

      {/* <Row>
          <Col>
          <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
          </Col>
        </Row> */}
    </>
  );
};

export default ClinicTable;
