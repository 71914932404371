import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/interceptor';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    user: {},
    meta: {},
    userList : [],
  };

  const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      addUserSuccess(state){
        state.isLoading = false;
      },

      getUserSuccess(state,action){
        state.isLoading = false;
        state.userList = action.payload.items;
        state.meta = action.payload.meta;
      },

      getUserByIdSuccess(state,action){
        state.isLoading = false;
        state.user = action.payload;
      }

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------

  export function addUser(data){
    return async () => {
      dispatch(slice.actions.startLoading());
      try{
        const response = await axios.post('/saas/create-user',data);
        dispatch(slice.actions.addUserSuccess(response.data));
        return { status: true }
      }
      catch(e){
        dispatch(slice.actions.hasError(e));
        return { status: false, payload: { error: 'Error' } };
      }
    }
  };

  export function updateUser(id,data){
    return async () => {
      dispatch(slice.actions.startLoading());
      try{
        const response = await axios.put(`/saas/update-user/${id}`,data);
        dispatch(slice.actions.addUserSuccess(response.data));
        return { status: true }
      }
      catch(e){
        dispatch(slice.actions.hasError(e));
        return { status: false, payload: { error: 'Error' } };
      }
    }
  };

  export function getUserList(page,pageSize) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/saas/user?page=${page}&take=${pageSize}`);
        dispatch(slice.actions.getUserSuccess(response.data.data));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };

  export function getUserById(id) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/saas/user?userId=${id}`);
        dispatch(slice.actions.getUserByIdSuccess(response.data.data.items[0]));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };

  export function changePassword(data) {
    return async () => {
      try {
        const res = await axios.put('/user/change-password',data);
        return res;
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
        return error;
      }
    };
  };

  export function resendEmail(email) {
    return async () => {
      try {
        await axios.post('/user/resend-email',{email});
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };