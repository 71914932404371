import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Box, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle } from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import * as toaster from '../../../utils/toaster';

// ----------------------------------------------------------------------

export default function SetPasswordForm() {
  const { token } = useParams();

  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, watch } = useForm();

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    digit: false,
    letter: false,
  });

  const handlePasswordChange = (e) => {
    const pass = e.target.value;
    setPasswordCriteria({
      length: pass.length >= 8,
      digit: /\d/.test(pass),
      letter: /[a-zA-Z]/.test(pass),
    });
  };

  const onSubmit = async (data) => {
    console.log(data);
    const {password, confirmPassword} = data;
    setLoading(true);

    if (!password || !confirmPassword) {
      setLoading(false);
      return;
    }

    if(password !== confirmPassword){
      setLoading(false);
      toaster.error('Password does not match');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/set-password/${token}`, { password });
      if (response.data.status) {
        navigate('/', { replace: true });
      }
      else {
        toaster.error('Error');
      }

    }
    catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('password', { 
            required: 'Password is required', 
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters long'
            },
            pattern: {
              value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
              message: 'Password must contain at least one letter and one number'
            }
          })}
          error={!!errors.password}
          helperText={errors.password ? errors.password.message : ''}
          onChange={handlePasswordChange}
        />

        <TextField
          name="confirmPassword"
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('confirmPassword', { 
            required: 'Password is required',
            validate: {message: (val) => {
              if(watch('password') !== val){
                return "Password does not match"
              }
              return true;
            }}
          })}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
        />

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              {passwordCriteria.length ? <CheckCircle color="success" /> : <CancelIcon color="error" />}
              <Typography variant="body2" sx={{ ml: 1 }}>
                Has at least 8 characters?
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              {passwordCriteria.digit ? <CheckCircle color="success" /> : <CancelIcon color="error" />}
              <Typography variant="body2" sx={{ ml: 1 }}>
                Has one digit?
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              {passwordCriteria.letter ? <CheckCircle color="success" /> : <CancelIcon color="error" />}
              <Typography variant="body2" sx={{ ml: 1 }}>
                Has one letter?
              </Typography>
            </Box>
          </Box>

        <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained">
          Set Password
        </LoadingButton>
      </Stack>
    </Box>
  );
}
