import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col, Card } from 'react-bootstrap'
import DashboardLineChart from "./chart/DashboardLineChart";
import InfoCards from '../components/dashboard/InfoCards'
import DashboardBarChart from './chart/DashboardBarChart';
import AppointmentCard from './AppointmentCard';
import { CONSTANT } from '../utils/constant';
import { useDispatch, useSelector } from '../redux/store';
import { getDashboardChartData, getDirectorDashboard, getProgramAdminDashboard, getSaasDashboardData } from '../redux/slices/organization';
import PatientDashboardList from './PatientDashboardList';
import { getPatientDashboard } from '../redux/slices/centerUser';

const DashboardAppPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  
  const { patientDashboard } = useSelector((state)=> state.centerUser);
  const [surveyPatient,setSurveyPatient] = useState([]);

  const dispatch = useDispatch();

  useEffect(()=>{
    if(user.role === CONSTANT.USER_ROLE.Patient){
      dispatch(getPatientDashboard());
    }
    else{
      dispatch(getDashboardChartData());
    }
  },[]);

  useEffect(()=>{
      if(patientDashboard && patientDashboard?.surveyPatient){
        console.log(patientDashboard?.surveyPatient);
          setSurveyPatient(patientDashboard?.surveyPatient);
      }
  },[patientDashboard]);

  return (
    <div>
      
      <Helmet>
        <title> MAPS | Measurement Assisted Practice System </title>
      </Helmet>

      <Container>
        {user.role === CONSTANT.USER_ROLE.Patient ?
        <>
          <Row className='mb-5'>
              <AppointmentCard/>
          </Row>
          <Row>
            <PatientDashboardList patientTable={surveyPatient}/>
          </Row>
        </> : <>
        <Row className='mb-5'>
          <InfoCards />
        </Row>
        <Row className='mt-5'>
        <Col md={6}>
                <Card className='px-3 shadow-sm rounded-3' border='0'>
                    <DashboardBarChart/>
                    <div className='mt-3'>
                        <h6 className='mb-0 pb-0 text-dark'>Patient Registrations</h6>
                        <small className='mt-0 pt-0' style={{color:'#ccc'}}>Monthly Graph</small>
                    </div>
                  </Card>
                </Col>

            <Col md={6}>
                <Card className='shadow-sm px-3  rounded-3' border='0' >
                    <DashboardLineChart/>
                    <div className='mt-3'>
                        <h6 className='mb-0 pb-0 text-dark'>Surveys Completed</h6>
                        <small className='mt-0 pt-0' style={{color:'#ccc'}}>This week</small>
                    </div>
                </Card>
            </Col>

            
        </Row>
        </>}
      </Container>
    </div>
  )
}

export default DashboardAppPage

