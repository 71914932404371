import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserPermissionTable from '../../components/tables/UserPermissionTable';

const UserList = () => (
    <div>
      <Container>
        <Row>
          <Col>
            <Card className="shadow-sm px-3 py-4" border="0">
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <h3 className="text-secondary">User Permissions</h3>
                <div>
                  <Link
                    to="/dashboard/UserPermissions"
                    className='me-3'
                    style={{
                        background: '#6A6A6A',
                        padding: '13px 25px',
                        border: 'none',
                        borderRadius: '6px',
                        fontSize: '0.875rem',
                        color: '#fff',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                    }}
                  >
                    Add Custom Role
                  </Link>
                  <Link
                    to="/dashboard/UserCreation"
                    style={{
                      background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                      padding: '13px 25px',
                      color: '#fff',
                      borderRadius: '6px',
                      fontSize: '0.875rem',
                    }}
                  >
                    Add New Member
                  </Link>
                </div>
              </div>
              <UserPermissionTable />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );

export default UserList;
