import React from 'react';
import Table from 'react-bootstrap/Table';
import { FaPen, FaTrash, FaUser } from 'react-icons/fa';
import { useSelector } from '../../redux/store';

const OrganizationClinicTable = () => {

  const {organization} = useSelector((state)=> state.organization);

  return (
    <>
      <Table striped="rows" className="clinic-table">
        <thead>
          <tr>
            <th>Clinics Name</th>
            <th className="text-center">Directors</th>
            <th>Location</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {organization && organization?.clinics?.length > 0 ?
            organization.clinics.map((clinic)=>(
              <tr key={clinic.id}>
                <td>{clinic.name}</td>
                <td className="text-center">
                  <span
                    className="rounded-circle"
                    style={{
                      background: '#ECECEC',
                      color: '#ACACAC',
                      fontSize: '12px',
                      padding: '6px 7px',
                    }}
                  >
                    <FaUser />
                  </span>
                  <br />
                  <small>{clinic?.director ? clinic.director.name : "+ Assign"}</small>
                </td>
                <td>
                  {clinic.description}
                </td>
                <td>{clinic.status ? "Active":"Inactive"}</td>
              </tr>
            ))
            :
            <tr><td colSpan={4}><p style={{textAlign:'center'}}>No Clinics Found!</p></td></tr>
          }
          
        </tbody>
      </Table>
    </>
  );
};

export default OrganizationClinicTable;
