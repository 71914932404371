import React, { useEffect, useMemo, lazy, Suspense, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import { surveyLocalization, setLicenseKey, Serializer, ComponentCollection } from "survey-core";
import { localization } from "survey-creator-core";
import { ReactElementFactory } from "survey-react-ui";
import "../styles/surveyjs.css";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import { Converter } from "showdown";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "../redux/store";
import { CONSTANT } from "../utils/constant";
import { getDirectorSurveyById, getPaSurveyById, getSurveyById } from "../redux/slices/survey";
import { saveSurvey, updateSurveySettings } from "../utils/apiSurvey";

setLicenseKey(
    "OTgyZmU3MDUtMmJmZC00MTk5LTg5OWQtMzllZmYyY2VmY2U3OzE9MjAyNS0wNi0xMywyPTIwMjUtMDYtMTMsND0yMDI1LTA2LTEz"
);

surveyLocalization.supportedLocales = ["en", "de", "es", "fr"];
localization.getLocale("").ed.designer = "Create Survey";

Serializer.addProperty("question", {
    name: "hiddenDescription",
    category: "general",
    type: "text",
    default: "normal"
});

const PublicSurvey = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const [survey,setSurvey] = useState([]);

    const creator = useMemo(() => {
        const options = {
            showTranslationTab: false,
            showJSONEditorTab: false,
            showLogicTab: false,
            isAutoSave: false,
            showThemeTab: false,
            showDesignerTab : false,
            showEmbeddedSurveyTab: true,
            questionTypes: [
                "radiogroup",
                "rating",
                "checkbox",
                "dropdown",
                "multidropdown",
                "comment",
                "boolean",
                "ranking",
                "text",
                "html",
                "image",
                "panel",
                "tagbox"
            ]
        };
        return new SurveyCreator(options);
    }, []);
    const converter = new Converter();
    creator.onSurveyInstanceCreated.add((sender, options) => {
        if (options.reason === 'test') {
            options.survey.onTextMarkdown.add((survey, options) => {
                let str = converter.makeHtml(options.text);
                str = str.substring(3);
                str = str.substring(0, str.length - 4);
                options.html = str;
            });
        }
    });
    creator.saveSurveyFunc = async (saveNo, callback) => {
        const results = await updateSurveySettings(params.surveyId, creator.JSON);
        console.log(results.message === "request entity too large");
        if (results.message === "request entity too large") {
            toast.error("Image size should not exceed 100KB");
        }
        callback(saveNo, true);
    };

    const saveSurvey = (sender) => {
        const surveyJson = sender.JSON;
        console.log('Survey JSON:', surveyJson);
        // You can send the surveyJson to your server or save it locally
      };
    
      creator.saveSurveyFunc = saveSurvey;

    const getSurvey = async () =>{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/survey/${params.token}`);
        console.log(res.data);
        setSurvey(res.data.data);
    }

    useEffect(()=>{
        getSurvey();
    },[params.token]);

    useEffect(() => {
        creator.JSON = survey;
    }, [creator, params.token, survey]);

    return (
        <div style={{ height: "100%" }}>
            {creator && (
                <Suspense fallback={<div>Loading...</div>}>
                    <SurveyCreatorComponent creator={creator} />
                </Suspense>
            )}
        </div>
    );
};

export default PublicSurvey;
