import PropTypes from 'prop-types';
import { useState } from 'react'; // Import useState hook
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const user = JSON.parse(localStorage.getItem('user'));
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.filter(menu=> menu?.role?.includes(user.role)).map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, children } = item;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleItemClick = (event) => {
    if (children) {
      event.preventDefault();
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}
        sx={{
          '&.active': {
            color: '#fff',
            bgcolor: '#FDA61C',
            fontWeight: 'fontWeightBold',
          },
        }}
        onClick={handleItemClick}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} sx={{ color: '#fff' }} />
        {info && info}
        {children && (isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
      </StyledNavItem>

      {isExpanded && children && (
        <List disablePadding>
          {children.map((childItem) => (
            <NavItem key={childItem.title} item={childItem} />
          ))}
        </List>
      )}
    </>
  );
}
