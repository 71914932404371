import React from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Row, Col, Modal } from 'react-bootstrap';
import { CardMedia, Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const SendInvitationModal = (props) => {
  //   const [modalShow, setModalShow] = React.useState(false);

  return (
    <Modal {...props} className="inviteModal" size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-4">
        <Row>
          <Col md={9}>
            <div className="modalHeading">
              <h2>Send invitation</h2>
              <p>
                Assign a Director to <b>West End Clinic</b>
              </p>
            </div>
          </Col>
          <Col md={3}>
            <div className="crossBtn">
              <button type="button" onClick={props.onHide}>
                <CloseIcon />
              </button>
            </div>
          </Col>
        </Row>
        <div className="modalForm">
          <form action="">
            <Row>
              <Col md={6}>
                <input type="text" />
              </Col>
              <Col md={6}>
                <input type="text" />
              </Col>
              <Col md={12} className="mt-3">
                <input type="email" />
              </Col>
              <Col md={12} className="mt-3">
                <textarea name="" placeholder="Write invitation message (optional)" />
              </Col>
            </Row>
          </form>
        </div>
        <Row className="mt-4">
          <Col md={4}>
            <button type="button" className="copyBtn">
              <span>
                <ContentCopyIcon />
              </span>{' '}
              Copy Link
            </button>
          </Col>
          <Col md={8}>
            <Button
              onClick={props.onHide}
              style={{
                background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                padding: '6px 25px',
                border: 'none',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                color: '#fff',
                width: '100%',
              }}
            >
              Invite
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SendInvitationModal;
