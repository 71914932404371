import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Grid, InputAdornment } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import { CardGiftcard } from '@mui/icons-material';
import { Col, Form, Row } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from '../../redux/store';
import { addUser, getUserById, getUserList, updateUser } from '../../redux/slices/user';
import * as toaster from '../../utils/toaster';
import { getCustomRole } from '../../redux/slices/userPermission';

function UserCreationForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {id} = useParams();

  const [isEdit,setIsEdit] = useState(false);

  const {customRoleList} = useSelector((state)=> state.userPermission);
  const { user } = useSelector((state)=> state.user);

  const [data, setData] = useState({
    name: '',
    email: '',
    roleId: ''
  });

  useEffect(()=>{
    if(id){
      console.log("id",id);
      setIsEdit(true);
      dispatch(getUserById(id));
    }
  },[id]);

  useEffect(()=>{
    if(isEdit && user){
      const data = user;
      if(data){
        const obj ={
          name: data?.name,
          email: data?.email,
          roleId: data?.customRole?.id
        };
        setData(obj);
      }
    }
  },[user]);

  useEffect(()=>{
    dispatch(getCustomRole());
  },[dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
    console.log(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form data submitted:', data);
    let res;
    if(isEdit){
      res = await dispatch(updateUser(id,data));
    }
    else{
      res = await dispatch(addUser(data));
    } 
    if(res.status === true){
      toaster.success(`User ${isEdit ? 'updated':'created'} successfully!`);
      navigate("/dashboard/UserList");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="name"
            value={data.name}
            variant="outlined"
            placeholder="Name"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaUser />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            name="email"
            type='email'
            value={data.email}
            variant="outlined"
            placeholder="Email"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Form.Select value={data.roleId} name='roleId' onChange={handleChange} required>
            <option value={""} disabled>Select User Role</option>
            {customRoleList && customRoleList.map(customRole=>(
              <option key={customRole.id} value={customRole.id}>{customRole.name}</option>
            ))}
          </Form.Select>
        </Grid>
      </Grid>

      <Row className="mt-5 align-items-end">
        <Col xs={6}>
        <Link to="/dashboard/UserList">
          <Button
            type="button"
            variant="contained"
            className='btn btn-outline-secondary'
          >
            Back
          </Button>
        </Link>
        </Col>
        <Col xs={6} className="text-end">
          <Button
            type="submit"
            variant="contained"
            style={{
              background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            }}
          >
            {isEdit ? "Save" : "Create"}
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default UserCreationForm;
