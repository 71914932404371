import React from 'react';
import { Col, Container, Row, Card, Button, Table } from 'react-bootstrap';
import DownloadIcon from '@mui/icons-material/Download';
import { FaTrash } from 'react-icons/fa';
import AnswerModal from '../modal/AnswerModal';

const TwoWayTab = () => {
  return (
    <div>
      <Container>
        <Row className=" mt-4">
          <Col>
            <Card className=" shadow-sm px-5 py-4" border="0">
              <Row>
                <Col className="text-end" xs={12}>
                  <Button
                    className="btn btn-primary me-3"
                    style={{
                      background: '#6A6A6A',
                      border: 'none',
                      borderRadius: '6px',
                      fontSize: '0.875rem',
                      color: '#fff',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                    }}
                  >
                    <DownloadIcon /> Download
                  </Button>
                </Col>
                <Col xs={6}>
                  <form action="">
                    <Row>
                      <Col xs={12}>
                        <div className="formInputs">
                          <p className="mb-2">Sample Size</p>
                          <input type="text" />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="formInputs">
                          <p className="mb-2">Rows</p>
                          <select name="">
                            <option value="" selected hidden>
                              Select one
                            </option>
                          </select>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="formInputs">
                          <p className="mb-2">Columns</p>
                          <select name="">
                            <option value="" selected hidden>
                              Select one
                            </option>
                          </select>
                        </div>
                      </Col>
                      <Col xs={12} className="mt-4">
                        <Button
                          className="btn btn-primary"
                          style={{
                            background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                            border: 'none',
                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                            fontSize: '13px',
                          }}
                        >
                          Generate Table
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
              <div className="twoTableWrp">
                <Row>
                  <Col md={3} className='pe-0'>
                    <div className="sideHeading">
                      <h3>How much company environment is important?</h3>
                    </div>
                  </Col>
                  <Col md={9} className='ps-0'>
                    <div className="twoWayTableWrp">
                      <div className="upperTxt">
                        <h3>Do you want a better future?</h3>
                      </div>
                      <div className='tableContent'>
                        <Table>
                          <thead>
                            <tr>
                              <th>Question</th>
                              <th>Today</th>
                              <th>1 month ago, or less (but not today)</th>
                              <th>1 to 3 months ago</th>
                              <th>3 to 6 months ago</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>I am in recovery from alcohol and other drugs</td>
                              <td>0 (0% ↓) (0% →)</td>
                              <td>0 (0% ↓) (0% →)</td>
                              <td>0 (0% ↓) (0% →)</td>
                              <td>0 (0% ↓) (0% →)</td>
                              <td>0 (0% ↓) (0% →)</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TwoWayTab;
