import React, {useState} from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DateRangePicker } from "react-date-range";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import UserListTable from '../../components/tables/UserListTable';
import "react-date-range/dist/styles.css"; // Import the default styles
import "react-date-range/dist/theme/default.css"; // Import the default theme styles

const UserList = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [search, setSearch] = useState('');

  const handleDateSelect = (ranges) => {
    // Assuming you only need one date (e.g., start date)
    const selectedStartDate = ranges.selection.startDate;

    setSelectedDate(selectedStartDate);

    setShowCalendar(false);
  };

  const handleCalendarToggle = () => {
    setShowCalendar(!showCalendar);
  };
  
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Card className="shadow-sm px-3 py-4" border="0">
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <h3 className="text-secondary">User List</h3>
                <Link
                  to="/dashboard/UserCreation"
                  style={{
                    background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                    padding: '13px 25px',
                    color: '#fff',
                    borderRadius: '6px',
                    fontSize: '0.875rem',
                  }}
                >
                  Add New Member
                </Link>
              </div>
              <Row className="my-4 px-3 align-items-end">
                <Col className="d-flex align-items-center">
                  {/* <div className="leftFilter">
                    <span className="filterLabel">Status</span>
                    <select name="" id="">
                      <option value="">Option 1</option>
                    </select>
                  </div> */}
                  <div className="leftFilter ms-3">
                    {/* <span>Date Range</span>
                    <input type="date" /> */}
                    <span className="filterLabel">Date Range</span>
                    <button type='button' onClick={handleCalendarToggle} className="calendarBtn">
                      {/* {selectedDate ? selectedDate.toDateString() : 'Open Calendar'} */}
                      <CalendarMonthIcon /> Last 30 Days
                    </button>
                    {showCalendar && (
                      <DateRangePicker
                        ranges={[
                          {
                            startDate: selectedDate || new Date(),
                            endDate: selectedDate || new Date(),
                            key: 'selection',
                          },
                        ]}
                        onChange={handleDateSelect}
                      />
                    )}
                  </div>
                </Col>
                <Col>
                  <div className="searchFilter">
                    <input type="text" placeholder="Search" onChange={(e)=> setSearch(e.target.value)}/>
                  </div>
                </Col>
              </Row>
              <UserListTable search={search} />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserList;
