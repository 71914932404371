import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/interceptor';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    surveyList : {},
    question: {},
    responses: []
  };


  const slice = createSlice({
    name: 'surveyStats',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },

      getSurveySuccess(state, action){
        state.isLoading = false;
        state.surveyList = action.payload;
      },

      getQuestionByIdSuccess(state, action){
        state.isLoading = false;
        state.question = action.payload;
      },

      getResponsesByIdSuccess(state,action){
        slice.isLoading = false;
        state.responses = action.payload;
      }

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------

  export function getSurveyStatsById(id){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/saas/surveyStatics/${id}`);
        dispatch(slice.actions.getSurveySuccess(response.data.data));
        dispatch(slice.actions.getQuestionByIdSuccess(response.data.data.survey));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function getPASurveyStatsById(id){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/program-admin/surveyStatics/${id}`);
        dispatch(slice.actions.getSurveySuccess(response.data.data));
        dispatch(slice.actions.getQuestionByIdSuccess(response.data.data.survey));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function getDirectorSurveyStatsById(id){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/director/surveyStatics/${id}`);
        dispatch(slice.actions.getSurveySuccess(response.data.data));
        dispatch(slice.actions.getQuestionByIdSuccess(response.data.data.survey));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };