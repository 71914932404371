import React from 'react';
import {Card, Col, Row} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FaCopy, FaPen, FaTrash, FaPlusSquare } from 'react-icons/fa';

const OutcomeMeasureTableData = () => {

   

  return (
    <div>

<Card>


        <div className='bg-dark px-3' style={{paddingTop:'15px', paddingBottom:'1px',background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)'}} >
            <Table striped="rows" className='clinic-table measure-table'>
                <thead className='rounded-2' style={{}}>
                    <tr className='py-0 m-2 bg-white rounded-2'>
                    <th / >
                    <th className='ps-0'>Outcome Measure Title</th>
                    <th>No. of Questions</th>
                    <th>Last Updated</th>
                    </tr>
                </thead>
            </Table>
        </div>
        <div>
            

        
      <Table striped="rows" className='clinic-table measure-table'>
      <tbody>

        <tr>
          <td>
          <input
        type="checkbox"
      />
          </td>
          <td>Self Esteem</td>
          <td>5</td>
          <td>09/05/2023</td>
        </tr>

        <tr>
           <td>
         <input
        type="checkbox"
      />
          </td>
          <td>Gender and Sexual Orientation</td>
          <td>3</td>
          <td>09/05/2023</td>
        </tr>

        <tr>
           <td>
         <input
        type="checkbox"
      />
          </td>
          <td>Overdose and Needle Use</td>
          <td>7</td>
          <td>09/05/2023</td>
        </tr>

        <tr>
           <td>
         <input
        type="checkbox"
      />
          </td>
          <td>Craving and pain</td>
          <td>6</td>
          <td>09/05/2023</td>
        </tr>

        <tr>
           <td>
         <input
        type="checkbox"
      />
          </td>
          <td>Abstinence Self Efficancy</td>
          <td>6</td>
          <td>09/05/2023</td>
        </tr>

        <tr>
           <td>
         <input
        type="checkbox"
      />
          </td>
          <td>GAD - 7</td>
          <td>6</td>
          <td>09/05/2023</td>
        </tr>
       
      </tbody>
    </Table> 

    </div>

    <Row className='my-5 px-3 text-end'>
        <Col className='text-end'>
            <Link className='btn btn-outline-secondary me-3' 
                    to='/dashboard/OutcomeMeasureTable' 
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px', 
                            padding:'5px 25px',
                            fontSize:'13px' }}>Cancel
            </Link>
        <Link 
                type="submit" 
                variant="contained"
                to='/dashboard/OutcomeMeasureCreateTable' 
                className='text-light btn'
                style={{background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)', 
                        padding:'5px 25px',
                        border:'none', 
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'}}>Add to Survey
            </Link>  
        </Col>
    </Row>
    </Card>
    </div>
  )
}

export default OutcomeMeasureTableData

