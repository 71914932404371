import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Modal, Form } from 'react-bootstrap';
import {  CardMedia, Button } from '@mui/material';

const NewOrganizationModal = (props) => {
  const navigator = useNavigate();
  const [orgType,setOrgType]= useState();

  const handleChange=(e)=>{
    setOrgType(e.target.value);
  }

  const navigate = () =>{
    navigator(`/dashboard/add-organization/${orgType}`);
  }

  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-4 text-center">
        <div className=" ps-5 text-center">
        <Form.Select onChange={handleChange}>
          <option value="" disabled selected>Select Organization Type</option>
          <option value="program-admin">Program Admin</option>
          {/* <option value="clinic">Clinic</option> */}
        </Form.Select>
        </div>
        <Row className="mt-4">
          <Col className="text-center">
            <Button
              type="button"
              variant="contained"
              onClick={navigate}
              style={{
                background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                padding: '13px 25px',
                border: 'none',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
              }}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default NewOrganizationModal;
