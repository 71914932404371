import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, InputAdornment } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import {
  FolderZip,
  LocalHospitalOutlined,
  LocalHospitalTwoTone,
  LocationCity,
  LocationCityOutlined,
  LocationCityTwoTone,
} from '@mui/icons-material';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/interceptor';
import { useDispatch, useSelector } from '../../redux/store';
import { deactivateOrganization, getOrganizationById, getOrganizationList, removeOrganization, updateOrganization } from '../../redux/slices/organization';
import DeleteModal from '../modal/DeleteModal';

function EditOrganizationForm({orgType}) {
  const navigate = useNavigate();
  
  const [modalShow, setModalShow] = useState(false);

  const [organizationData, setOrganizationData] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    type: orgType === '',
    description: `This is for ${orgType}`
  });
  
  const dispatch = useDispatch();
  const { organization } = useSelector((state)=> state.organization);

  useEffect(()=>{
      setOrganizationData({
        name: organization?.name,
        address1: organization?.address?.address1,
        address2: organization?.address?.address2,
        city: organization?.address?.city,
        state: organization?.address?.state,
        zip: organization?.address?.zip,
        phone: organization?.phone,
        type: organization?.type,
        description: organization?.description
      });
  },[organization]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOrganizationData({
      ...organizationData,
      [name]: value,
    });
  };

  const handleChangeNumber = (event) => {
    const { name, value } = event.target;
    const val = value.replace(/\D/g, '');
    const cleanValue = val.slice(0, 10);
    setOrganizationData({
      ...organizationData,
      [name]: cleanValue,
    });
  };

  const convertPhoneNumber = (number) =>{
    if(number){
      const cleanNumber = number.replace(/-/g, '');
      const formattedNumber = cleanNumber.replace(/(\d{3})(\d{3})(\d{4})/, (match, p1, p2, p3) => {
        if (p3) return `${p1}-${p2}-${p3}`;
        if (p2) return `${p1}-${p2}`;
        return p1;
      });
      return formattedNumber;
    }
      return '';
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    await dispatch(updateOrganization(organization?.id,organizationData));
    navigate("/dashboard/organization");    
  };

  const handleDelete = async () => {
    await dispatch(removeOrganization(organization?.id));
    navigate("/dashboard/organization");
  }

  const deactivateOrg = async () => {
    await dispatch(deactivateOrganization(organization?.id, !organization?.status));
    dispatch(getOrganizationById(organization?.id));
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <small className="text-secondary" style={{ fontWeight: '500' }}>
            License Type
          </small>
        </Grid>
        <Grid item xs={12}>
          <Form.Select name='type' onChange={handleChange} required value={organizationData?.type}>
            <option value="" disabled>Select Organization Type</option>
            <option value="program-admin">Program Admin</option>
            {/* <option value="clinic">Clinic</option> */}
          </Form.Select>
        </Grid>

        <Grid item xs={12} marginTop={2}>
          <small className="text-secondary" style={{ fontWeight: '500' }}>
            Organization Details
          </small>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="name"
            variant="outlined"
            placeholder="Organization Name"
            value={organizationData?.name}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalHospitalTwoTone />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="address1"
            variant="outlined"
            placeholder="Address 1"
            value={organizationData.address1}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="address2"
            placeholder="Address 2"
            variant="outlined"
            value={organizationData.address2}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
            
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="city"
            placeholder="City"
            variant="outlined"
            value={organizationData.city}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCity />
                </InputAdornment>
              ),
              inputProps: { inputMode: 'text', pattern: '[A-Za-z]*' }
            }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="state"
            placeholder="State"
            variant="outlined"
            value={organizationData.state}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCityTwoTone />
                </InputAdornment>
              ),
              inputProps: { inputMode: 'text', pattern: '[A-Za-z]*' }
            }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="zip"
            placeholder="ZIP"
            variant="outlined"
            value={organizationData.zip}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FolderZip />
                </InputAdornment>
              ),
              inputProps: { inputMode: 'numeric', pattern: '[0-9]*' }
            }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="phone"
            placeholder="Phone"
            variant="outlined"
            value={convertPhoneNumber(organizationData.phone)}
            onChange={handleChangeNumber}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
      </Grid>

      <Row className="mt-5 align-items-center">
        
        <Col xs={12}>
          <small className="text-secondary" style={{ fontWeight: '500' }}>
            Save organization information to assign admin
          </small>
        </Col>
        <Col className="text-end">
        
          <Button
            type="button"
            onClick={() => setModalShow(true)}
            variant="contained"
            className="me-3 btn btn-outline-danger"
            style={{
              padding: '13px 25px',
            }}
          >
            Delete
          </Button>
          <Button
            type="button"
            onClick={() => deactivateOrg()}
            variant="contained"
            className="me-3"
            style={{
              background: '#6A6A6A',
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            }}
          >
            {organization?.status ? 'Deactivate':'Activate'} Organization
          </Button>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            }}
          >
            Save Changes
          </Button>
        </Col>
      </Row>
      
      <DeleteModal
        show={modalShow}
        dltModalTxt={'Are you sure you want to delete this organization?'}
        onHide={() => setModalShow(false)}
        onDelete={()=> handleDelete()}
      />
      {/* <Button type="submit" variant="contained" color="primary">
        Submit
      </Button> */}
    </form>
  );
}

export default EditOrganizationForm;
