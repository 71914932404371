import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Image } from 'react-bootstrap';
import { Container, Typography } from '@mui/material';
import SetPasswordForm from '../sections/auth/login/SetPasswordForm';
import MapsLogo from '../assets/images/MAPS_logo.svg';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
    textAlign: 'center'
}));

// ----------------------------------------------------------------------

export default function SetPasswordPage() {
    return (
        <>
            <Helmet>
                <title> MAPS | Measurement Assisted Practice System </title>
            </Helmet>

            <StyledRoot>
                <Container maxWidth="sm">
                    <StyledContent>
                    <Image style={{
                        width: "266px",
                        height: "54px",
                        display: 'block',
                        margin: '0 auto'
                    }} src={MapsLogo} alt='logo maps' />
                    <Typography style={{ marginBottom: '40px', marginTop: '40px' }} variant="h4" gutterBottom>
                        Set Password
                    </Typography>
                        <SetPasswordForm />
                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
