import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, InputAdornment } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PhoneIcon from '@mui/icons-material/Phone';
import { FaUser } from 'react-icons/fa';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DeleteModal from '../modal/DeleteModal';
import PasswordResetModal from '../modal/PasswordResetModal';
import SendInvitationModal from '../modal/SendInvitationModal';
import SaveChangesModal from '../modal/SaveChangesModal';
import PasswordChangeModal from '../modal/PasswordChangeModal';
import { useDispatch, useSelector } from '../../redux/store';
import SendOrgAdminInvitationModal from '../modal/SendOrgAdminInvitationModal';
import { getOrganizationById, removeOrganizationAdmin, updateOrganizationAdmin } from '../../redux/slices/organization';
import { resendEmail } from '../../redux/slices/user';

function OrganizationAdminForm() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organization } = useSelector((state)=> state.organization);

  const [modalShow, setModalShow] = React.useState(false);
  const [openResetModal, setOpenResetModal] = React.useState(false);
  const [openInvitaionModal, setOpenInvitaionModal] = React.useState(false);
  const [saveChnagesModal, setSaveChnagesModal] = React.useState(false);
  const [passwordSuccessfullModal, setPasswordSuccessfullModal] = React.useState(false);
  const [data, setData] = useState({
    name: '',
    phoneNumber: '',
    email: ''
  });

  useEffect(()=>{
    if(organization && organization?.admin){
      setData({
        name: organization?.admin.name,
        phoneNumber: organization?.admin.phoneNumber,
        email: organization?.admin.email
      })
    }
  },[organization])

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateOrganizationAdmin(organization.admin.id,data));
    // Handle form submission here, e.g., send data to server or perform actions
    console.log('Form data submitted:', data);
  };

  const handleChangeNumber = (event) => {
    const { name, value } = event.target;
    const val = value.replace(/\D/g, '');
    const cleanValue = val.slice(0, 10);
    setData({
      ...data,
      [name]: cleanValue,
    });
  };

  const convertPhoneNumber = (number) =>{
    if(number){
      const cleanNumber = number.replace(/-/g, '');
      const formattedNumber = cleanNumber.replace(/(\d{3})(\d{3})(\d{4})/, (match, p1, p2, p3) => {
        if (p3) return `${p1}-${p2}-${p3}`;
        if (p2) return `${p1}-${p2}`;
        return p1;
      });
      return formattedNumber;
    }
      return '';
  }

  const handleDelete = async () => {
    await dispatch(removeOrganizationAdmin(organization.admin.id));
    dispatch(getOrganizationById(organization?.id));
    setModalShow(false);
  }

  const sendEmail = () => {
    dispatch(resendEmail(organization.admin.email));
  }

  return (
    <>
      <Row>
        <Col md={3}>
          <div className="assignDirectorBtn">
            <span>
              <PersonIcon />
            </span>
            <button type="button" style={{display: organization.admin === null ? "block":"none"}} onClick={() => setOpenInvitaionModal(true)}>
              + Assign an Admin
            </button>
            <button type='button' style={{display: organization?.admin?.name && organization?.admin['Invitation Accepted'] === false ? "block":"none"}} onClick={sendEmail}>
              Resend Email
            </button>
          </div>
        </Col>
        <Col md={9} style={{display: organization.admin === null ? "none":"block"}}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name="firstName"
                  variant="outlined"
                  value={data.name}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaUser />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  disabled
                  name="lastName"
                  variant="outlined"
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaUser />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid> */}

              {data.phoneNumber &&
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  disabled
                  name="phoneNumber"
                  value={convertPhoneNumber(data.phoneNumber)}
                  variant="outlined"
                  onChange={handleChangeNumber}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>}

              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name="email"
                  variant="outlined"
                  value={data.email}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
          <p style={{ color: '#B1B1B1', marginTop: '30px' }}>Clinic Assigned </p>
          <TextField
            fullWidth
            name="phone"
            variant="outlined"
            value="West End clinic"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaBuilding className="text-primary" />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid> */}
            </Grid>

            {/* <Button type="submit" variant="contained" color="primary">
        Submit
      </Button> */}
          </form>
        </Col>
      </Row>
      <Row className=" align-items-end" style={{ marginTop: '120px' }}>
      {organization?.admin?.name && organization?.admin['Invitation Accepted'] === false &&
        <Col xs={12}>
          <small className="text-secondary" style={{ fontWeight: '500' }}>
            Invitation Request Pending.
          </small>
        </Col>
      }
        <Col xs={3}>
          <Link
            className="btn btn-outline-secondary me-3"
            to="/dashboard/organization"
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
              padding: '13px 25px',
              fontSize: '13px',
            }}
          >
            Back
          </Link>
        </Col>
        <Col className="text-end" xs={9}>
          <Button
            type="button"
            onClick={() => setModalShow(true)}
            variant="contained"
            className="me-3 btn btn-outline-danger"
            style={{
              padding: '13px 25px',
            }}
          >
            Delete Admin
          </Button> 
          {/* <Button
            type="submit"
            onClick={() => setOpenResetModal(true)}
            variant="contained"
            className="me-3"
            style={{
              background: '#6A6A6A',
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            }}
          >
            Reset Password
          </Button> */}
          <Button
            type="submit"
            variant="contained"
            style={{
              background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            }}
          >
            Save Changes
          </Button>
        </Col>
      </Row>
      <DeleteModal
        show={modalShow}
        dltModalTxt={'Are you sure you want to delete this admin'}
        onHide={() => setModalShow(false)}
        onDelete={()=> handleDelete()}
      />
      <PasswordResetModal show={openResetModal} onHide={() => setOpenResetModal(false)} />
      <SendOrgAdminInvitationModal show={openInvitaionModal} onHide={() => setOpenInvitaionModal(false)} />
      <SaveChangesModal show={saveChnagesModal} onHide={() => setSaveChnagesModal(false)} />
      <PasswordChangeModal show={passwordSuccessfullModal} onHide={() => setPasswordSuccessfullModal(false)} />
    </>
  );
}

export default OrganizationAdminForm;
