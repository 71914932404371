import React, { useState } from 'react';
import { Card, Col, Container, Row, Button, Form, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaPen } from 'react-icons/fa';
import { format } from 'date-fns';
import { TextField } from '@mui/material';
import OrganizationTable from '../components/tables/OrganizationTable';
import NewOrganizationModal from '../components/modal/NewOrganizationModal';

const PatientDashboardList = ({ patientTable }) => {
  
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Card className=" shadow-sm px-2 pt-4 pb-3 pe-4" border="0">
              <Row>
                <Col className="mt-4">
                  <Table striped="rows" className="clinic-table">
                    <thead>
                      <tr>
                        <th className="text-center">Survey Name</th>
                        <th>Date Assigned</th>
                        <th>Status</th>
                        <th>Completion Rate</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {patientTable && patientTable.map(data=>(
                        <tr key={data?.id}>
                          <td className="text-center">
                            {data?.title || data?.survey}
                          </td>
                          <td>
                            {data?.createdAt ? <>{format(new Date(data.createdAt), "MM/dd/yyyy")}</> : '03/22/2022'}
                          </td>
                          <td>{ data?.submitted ? "Submitted" : "New"}</td>
                          <td>
                            {data?.completionRate || (data?.submitted ? '100%' : '0%')}
                          </td>
                          <td>
                            <Link to={`${data?.surveyLink}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#000' }}>
                              <div
                              style={{
                                background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                padding: '0px 5px 2px',
                                borderRadius: '50%',
                                textAlign: 'center',
                                float: 'left',
                              }}>
                                <FaPen style={{fill: '#fff', fontSize: '11px'}}/>
                              </div>
                              &nbsp; {data?.submitted ? 'Completed':'Start Survey'}
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PatientDashboardList;
