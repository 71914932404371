import React, { useState } from 'react';
import { TextField, Button, Grid, InputAdornment } from '@mui/material';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PhoneIcon from '@mui/icons-material/Phone';
import { FaUser } from 'react-icons/fa';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DeleteModal from '../modal/DeleteModal';
import PasswordResetModal from '../modal/PasswordResetModal';
import SendInvitationModal from '../modal/SendInvitationModal';
import SaveChangesModal from '../modal/SaveChangesModal';
import PasswordChangeModal from '../modal/PasswordChangeModal';

function ClinicDirectForm() {
  const [modalShow, setModalShow] = React.useState(false);
  const [openResetModal, setOpenResetModal] = React.useState(false);
  const [openInvitaionModal, setOpenInvitaionModal] = React.useState(false);
  const [saveChnagesModal, setSaveChnagesModal] = React.useState(false);
  const [passwordSuccessfullModal, setPasswordSuccessfullModal] = React.useState(false);
  const [clinicData, setClinicData] = useState({
    clinicName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setClinicData({
      ...clinicData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here, e.g., send data to server or perform actions
    console.log('Form data submitted:', clinicData);
  };

  return (
    <>
      <Row>
        <Col md={3}>
          <div className="assignDirectorBtn">
            <span>
              <PersonIcon />
            </span>
            <button type="button" onClick={() => setOpenInvitaionModal(true)}>
              + Assign a Director
            </button>
          </div>
        </Col>
        <Col md={9}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="firstName"
                  variant="outlined"
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaUser />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="lastName"
                  variant="outlined"
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaUser />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name="phone"
                  variant="outlined"
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name="email"
                  variant="outlined"
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
          <p style={{ color: '#B1B1B1', marginTop: '30px' }}>Clinic Assigned </p>
          <TextField
            fullWidth
            name="phone"
            variant="outlined"
            value="West End clinic"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaBuilding className="text-primary" />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid> */}
            </Grid>

            {/* <Button type="submit" variant="contained" color="primary">
        Submit
      </Button> */}
          </form>
        </Col>
      </Row>
      <Row className=" align-items-end" style={{ marginTop: '120px' }}>
        <Col xs={3}>
          <Link
            className="btn btn-outline-secondary me-3"
            to="/dashboard/ClinicList"
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
              padding: '13px 25px',
              fontSize: '13px',
            }}
          >
            Back
          </Link>
        </Col>
        <Col className="text-end" xs={9}>
          <Button
            type="submit"
            onClick={() => setModalShow(true)}
            variant="contained"
            className="me-3 btn btn-outline-danger"
            style={{
              padding: '13px 25px',
            }}
          >
            Delete
          </Button>
          <Button
            type="submit"
            onClick={() => setOpenResetModal(true)}
            variant="contained"
            className="me-3"
            style={{
              background: '#6A6A6A',
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            }}
          >
            Reset Password
          </Button>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            }}
          >
            Save Changes
          </Button>
        </Col>
      </Row>
      <DeleteModal
        show={modalShow}
        dltModalTxt={'Are you sure you want to delete this patient in this list'}
        onHide={() => setModalShow(false)}
      />
      <PasswordResetModal show={openResetModal} onHide={() => setOpenResetModal(false)} />
      <SendInvitationModal show={openInvitaionModal} onHide={() => setOpenInvitaionModal(false)} />
      <SaveChangesModal show={saveChnagesModal} onHide={() => setSaveChnagesModal(false)} />
      <PasswordChangeModal show={passwordSuccessfullModal} onHide={() => setPasswordSuccessfullModal(false)} />
    </>
  );
}

export default ClinicDirectForm;
