import React, { useState } from 'react';
import { Card, Col, Container, Row, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import OrganizationTable from '../components/tables/OrganizationTable';
import NewOrganizationModal from '../components/modal/NewOrganizationModal';

const OrganizationList = () => {
  
  const [modalShow, setModalShow] = useState(false);
  const [search, setSearch] = useState('');
  const [status,setStatus] = useState('');
  const [licenseType, setLicenseType] = useState('');

  const handleChange = (key,e) => {
    key(e.target.value);
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Card className=" shadow-sm px-2 pt-4 pb-3 pe-4" border="0">
              <Row className="px-3">
                <Col>
                  <h4 className="text-secondary">Organizations</h4>
                </Col>
                <Col>
                  <Form.Select onChange={(e)=>handleChange(setLicenseType,e)}>
                    <option value={""} selected disabled>License Type</option>
                    <option value={"admin"}>Program Admin</option>
                    {/* <option value={"clinic"}>Clinic</option> */}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select onChange={(e)=>handleChange(setStatus,e)}>
                    <option value={""} selected disabled>Status</option>
                    <option value={"active"}>Active</option>
                    <option value={"inactive"}>Inactive</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Control type='text' name='search' placeholder='Search' onChange={(e)=> setSearch(e.target.value)}/>
                </Col>
                <Col className="text-end">
                  <Link
                    className="btn btn-primary"
                    onClick={()=>setModalShow(true)}
                    style={{
                      background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                      border: 'none',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                      fontSize: '13px',
                    }}
                  >
                    + Add Organization
                  </Link>
                </Col>
              </Row>

              <NewOrganizationModal show={modalShow} onHide={()=>setModalShow(false)} />

              {/* <Row className='my-4 px-3 align-items-end'>
                <Col>
                <div className='leftFilter'>
                  <span>Status</span>
                  <select name="" id="">
                    <option value="">Option 1</option>
                  </select>
                </div>
                </Col>
                <Col>
                <div className='searchFilter'>
                  <input type="text" placeholder='Search' />
                </div>
                </Col>
              </Row> */}

              <Row>
                <Col className="mt-4">
                  <OrganizationTable search={search} status={status} licenseType={licenseType} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrganizationList;
