import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { FaCopy, FaPlusSquare, FaPen } from 'react-icons/fa';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

const QuestionsTabTable = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col style={{ height: '200px' }}>
            <Row>
              <Col
                className="pt-2 bg-primary"
                style={{ background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)', height: '50px' }}
              >
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>Questions</th>
                      <th>Answer Type</th>
                      <th className="text-center">No.</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>How much company environment is important?</td>
                      <td>Multiple choice - single answer</td>
                      <td className="text-center">1</td>
                      <td className="text-center">
                        <Nav className="questionTableDropdown">
                          <NavDropdown
                            title={<FaPlusSquare className="text-primary" style={{ boxShadow: '  -3px 3px' }} />}
                            className="surveyDropdowm"
                          >
                            <NavDropdown.Item href="#action/3.1" className="dropDownItem">
                              <Link to="/dashboard/UpdateQuestions" style={{ color: '#505050' }}>
                                <div
                                style={{
                                  background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                  padding: '0px 5px 2px',
                                  borderRadius: '50%',
                                  textAlign: 'center',
                                  float: 'left',
                                }}>
                                  <FaPen style={{fill: '#fff', fontSize: '11px'}}/>
                                </div>
                                &nbsp; Edit
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                              <Link to="/dashboard/AddScore" style={{ color: '#505050' }}>
                                <FaPen
                                  style={{
                                    background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                    padding: '5px',
                                    borderRadius: '50%',
                                    fill: '#fff',
                                    fontSize: '20px',
                                    marginRight: '5px',
                                  }}
                                />{' '}
                                Add Score
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                              <Link to="/dashboard/SkipLogic" style={{ color: '#505050' }}>
                                <FaPen
                                  style={{
                                    background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                    padding: '5px',
                                    borderRadius: '50%',
                                    fill: '#fff',
                                    fontSize: '20px',
                                    marginRight: '5px',
                                  }}
                                />{' '}
                                Logic
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                              <FaPen
                                style={{
                                  background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                  padding: '5px',
                                  borderRadius: '50%',
                                  fill: '#fff',
                                  fontSize: '20px',
                                  marginRight: '5px',
                                }}
                              />{' '}
                              Duplicate
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3" className="dropDownItem">
                              <FaPen
                                style={{
                                  background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                  padding: '5px',
                                  borderRadius: '50%',
                                  fill: '#fff',
                                  fontSize: '20px',
                                  marginRight: '5px',
                                }}
                              />{' '}
                              Delete
                            </NavDropdown.Item>
                          </NavDropdown>
                        </Nav>
                      </td>
                    </tr>
                    <tr>
                      <td>Do you want a better future?</td>
                      <td>Dropdown</td>
                      <td className="text-center">2</td>
                      <td className="text-center">
                        <Nav className="questionTableDropdown">
                          <NavDropdown
                            title={<FaPlusSquare className="text-primary" style={{ boxShadow: '  -3px 3px' }} />}
                            className="surveyDropdowm"
                          >
                            <NavDropdown.Item href="#action/3.1" className="dropDownItem">
                              <Link to="/dashboard/UpdateQuestions" style={{ color: '#505050' }}>
                                <FaPen
                                  style={{
                                    background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                    padding: '5px',
                                    borderRadius: '50%',
                                    fill: '#fff',
                                    fontSize: '20px',
                                    marginRight: '5px',
                                  }}
                                />{' '}
                                Edit
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                              <FaPen
                                style={{
                                  background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                  padding: '5px',
                                  borderRadius: '50%',
                                  fill: '#fff',
                                  fontSize: '20px',
                                  marginRight: '5px',
                                }}
                              />{' '}
                              Add Score
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                              <FaPen
                                style={{
                                  background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                  padding: '5px',
                                  borderRadius: '50%',
                                  fill: '#fff',
                                  fontSize: '20px',
                                  marginRight: '5px',
                                }}
                              />{' '}
                              Logic
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                              <FaPen
                                style={{
                                  background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                  padding: '5px',
                                  borderRadius: '50%',
                                  fill: '#fff',
                                  fontSize: '20px',
                                  marginRight: '5px',
                                }}
                              />{' '}
                              Duplicate
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3" className="dropDownItem">
                              <FaPen
                                style={{
                                  background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                  padding: '5px',
                                  borderRadius: '50%',
                                  fill: '#fff',
                                  fontSize: '20px',
                                  marginRight: '5px',
                                }}
                              />{' '}
                              Delete
                            </NavDropdown.Item>
                          </NavDropdown>
                        </Nav>
                      </td>
                    </tr>
                    <tr>
                      <td>Write some article to achieve the best health of life</td>
                      <td>Short text</td>
                      <td className="text-center">3</td>
                      <td className="text-center">
                        <Nav className="questionTableDropdown" style={{ padding: '0' }}>
                          <NavDropdown
                            title={<FaPlusSquare className="text-primary" style={{ boxShadow: '  -3px 3px' }} />}
                            className="surveyDropdowm"
                          >
                            <NavDropdown.Item href="#action/3.1" className="dropDownItem">
                              <Link to="/dashboard/UpdateQuestions" style={{ color: '#505050' }}>
                                <FaPen
                                  style={{
                                    background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                    padding: '5px',
                                    borderRadius: '50%',
                                    fill: '#fff',
                                    fontSize: '20px',
                                    marginRight: '5px',
                                  }}
                                />{' '}
                                Edit
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                              <FaPen
                                style={{
                                  background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                  padding: '5px',
                                  borderRadius: '50%',
                                  fill: '#fff',
                                  fontSize: '20px',
                                  marginRight: '5px',
                                }}
                              />{' '}
                              Add Score
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                              <FaPen
                                style={{
                                  background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                  padding: '5px',
                                  borderRadius: '50%',
                                  fill: '#fff',
                                  fontSize: '20px',
                                  marginRight: '5px',
                                }}
                              />{' '}
                              Logic
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                              <FaPen
                                style={{
                                  background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                  padding: '5px',
                                  borderRadius: '50%',
                                  fill: '#fff',
                                  fontSize: '20px',
                                  marginRight: '5px',
                                }}
                              />{' '}
                              Duplicate
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3" className="dropDownItem">
                              <FaPen
                                style={{
                                  background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                  padding: '5px',
                                  borderRadius: '50%',
                                  fill: '#fff',
                                  fontSize: '20px',
                                  marginRight: '5px',
                                }}
                              />{' '}
                              Delete
                            </NavDropdown.Item>
                          </NavDropdown>
                        </Nav>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default QuestionsTabTable;
