import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Card, Modal, Button, Badge } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import { FaPen, FaTrash, FaUser } from 'react-icons/fa';
import DeleteModal from '../modal/DeleteModal';
import { useDispatch, useSelector } from '../../redux/store';
import { deleteCenter, getCenter, updateCenterStatus } from '../../redux/slices/programAdmin';

const CenterTable = ({search}) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [centerRow,setCenterRow] = React.useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { centerList, meta } = useSelector((state)=> state.programAdmin);

  const [selectedId,setSelectedId] = useState('');

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
      dispatch(getCenter(page+1,rowsPerPage));
  }, [page,rowsPerPage]);

  useEffect(()=>{
    setCenterRow(centerList);
  },[centerList])

  useEffect(()=>{
    if(meta){
      setTotalRows(meta.totalItems);
      setRowsPerPage(meta.itemsPerPage);
    }
  },[meta])

  useEffect(() => {
    if(search !== null && search !== ''){
      setCenterRow(centerList.filter(x=>x.name.toLowerCase().includes(search.toLowerCase())));
    }
    else{
      setCenterRow(centerList);
    }
  },[search]);
  
  const handleDelete = async (id) => {
    await dispatch(deleteCenter(id));
    setSelectedId('');
    await dispatch(getCenter());
    setOpenDeleteModal(false);
  }

  const deactivateCenter = async (id,status) => {
    await dispatch(updateCenterStatus(id, !status));
    await dispatch(getCenter());
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => {
      return acc && acc[part] !== undefined && acc[part] !== null ? acc[part] : null;
    }, obj);
  };

  const sortedData = [...centerRow].sort((a, b) => {
    if (orderBy) {
      const aValue = getNestedValue(a, orderBy);
      const bValue = getNestedValue(b, orderBy);

      if (aValue === null && bValue === null) return 0;
      if (aValue === null) return order === 'asc' ? 1 : -1;
      if (bValue === null) return order === 'asc' ? -1 : 1;

      return order === 'asc'
        ? aValue < bValue ? -1 : 1
        : aValue > bValue ? -1 : 1;
    }
    return 0;
  });

  return (
    <>
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleRequestSort('name')}
                  >
                  Center
                </TableSortLabel>
              </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'director.name'}
                    direction={orderBy === 'director.name' ? order : 'asc'}
                    onClick={() => handleRequestSort('director.name')}
                  >
                    Director
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel>
                    Location
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'status'}
                    direction={orderBy === 'status' ? order : 'asc'}
                    onClick={() => handleRequestSort('status')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{textAlign:'center'}}>
                    Actions
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData && sortedData.map((row, index) => (
              <TableRow hover key={index}>
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell>
                  {row.director?.name || "N/A"}
                </TableCell>
                <TableCell>
                  { row.address ? <>{row.address.address1 || ""},
                  <br /> {row.address.city || ""}, {row.address.zip || ""}</>: ""}
                </TableCell>
                <TableCell>
                  { row.status ? "Active" : "Inactive"}
                </TableCell>
                <TableCell>
                  <Link to={`/dashboard/edit-center/${row.id}`} style={{ textDecoration: 'none', color: '#000' }}>
                    <div
                    style={{
                      background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                      padding: '0px 5px 2px',
                      borderRadius: '50%',
                      textAlign: 'center',
                      float: 'left',
                    }}>
                      <FaPen style={{fill: '#fff', fontSize: '11px'}}/>
                    </div>
                    &nbsp; Manage
                  </Link>
                  <button
                    type="button"
                    onClick={()=>deactivateCenter(row.id,row.status)}
                    style={{ border: 'none', backgroundColor: 'transparent', padding: '0' }}
                  >
                    <FaTrash
                      type="button"
                      className="text-light p-2 rounded-circle bg-secondary ms-3"
                      style={{ width: '29px', height: '29px' }}
                    />{' '}
                    &nbsp; {row.status ? 'Deactivate' : 'Activate' }
                  </button>
                  <button
                    type="button"
                    onClick={() => {setSelectedId(row?.id); setOpenDeleteModal(true);}}
                    style={{ border: 'none', backgroundColor: 'transparent', padding: '0' }}
                  >
                    <FaTrash
                      type="button"
                      className="text-light p-2 rounded-circle bg-secondary ms-3"
                      style={{ width: '29px', height: '29px' }}
                    />{' '}
                    &nbsp; Delete
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRows}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      
      <DeleteModal dltModalTxt={"Are you sure you want to remove the center"} show={openDeleteModal} onDelete={()=>handleDelete(selectedId)} onHide={() => setOpenDeleteModal(false)} />
    </Paper>
    </>
  );
};

export default CenterTable;
