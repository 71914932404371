import React from 'react';
import { Col, Container, Row, Card, Form } from 'react-bootstrap';
import AddScoreQuestionField from './AddScoreQuestionField';

const AddScrore = () => {
  return (
    <div>
      <Container>
        <Row>
          {/* <Col>
               <QuestionTypeSelect />
            </Col> */}
          <Col>
            <Card className=" shadow-sm px-2 py-3" border="0">
              <Row className="px-3">
                <Col md={12}>
                  <h4 className="fw-bold text-secondary">Select Scoring Options</h4>
                </Col>
                <Col md={6} className="mt-3">
                  <select className="w-100 ms-0" style={{ height: '40px', border: '1px solid #ccc' }}>
                    <option value="" hidden selected>Select Scoring</option>
                    {/* <option value="option">Option 1</option> */}
                    <option value="">Values</option>
                    <option value="">Implement</option>
                    <option value="">Answer Divide</option>
                    <option value="">Value Divide</option>
                  </select>
                </Col>
              </Row>
              <Row className='mt-4'>
                <Col>
                  <AddScoreQuestionField />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddScrore;
