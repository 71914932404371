import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Container, Typography, Link as MuiLink } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useDispatch, useSelector } from '../redux/store';
import { getPatientDashboard } from '../redux/slices/centerUser';

const AppointmentCard = () => {

    const dispatch = useDispatch();

    const { patientDashboard } = useSelector((state)=> state.centerUser);

    function getNameInitials(name) {
        if (!name) return '';

        const words = name.trim().split(/\s+/);
        const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
        
        return initials;
    }

    function getFirstName(name) {
        if (!name) return '';

        const words = name.trim().split(/\s+/);
        
        return words[0];
    }
    
  return (
    <Container>
      <Row className="mt-4">
        <Col md={4}>
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center">
                <div className="avatar" style={avatarStyle}>{getNameInitials(patientDashboard?.name)}</div>
                <div>
                  <Typography variant="h5">{patientDashboard?.name}</Typography>
                </div>
              </div>
              <div className="mt-3">
                <div style={{height: '1px',background:'linear-gradient(to right,  transparent, transparent,#ccc,#ccc, transparent, transparent)', marginBottom:'1.5rem'}} />
                <Typography variant="body2">CLINIC INFO</Typography>
                
                <div style={{height: '1px',background:'linear-gradient(to right,  transparent, #ccc,#ccc,#ccc,  #ccc, transparent)', marginBottom:'0.5rem', marginTop:'0.5rem'}} />
                <Row>
                    <Col>
                        <Typography variant="body2" className='h-100' style={{alignContent:'center'}}>Clinic Name</Typography>
                    </Col>
                    <Col>
                        <Typography variant="body1">{patientDashboard?.clinic?.name}</Typography>
                        <Typography variant="body2">{patientDashboard?.clinic?.address?.address1}</Typography>
                    </Col>
                </Row>
                
                <div style={{height: '1px',background:'linear-gradient(to right,  transparent, #ccc,#ccc,#ccc,  #ccc, transparent)', marginBottom:'0.5rem', marginTop:'0.5rem'}} />
                <Row>
                    <Col>
                        <Typography variant="body2" className='h-100' style={{alignContent:'center'}}>Clinician</Typography>
                    </Col>
                    <Col>
                        <Typography variant="body1">Dr. Kelly Collins</Typography>
                    </Col>
                </Row>

                <div style={{height: '1px',background:'linear-gradient(to right,  transparent, #ccc,#ccc,#ccc,  #ccc, transparent)', marginBottom:'0.5rem', marginTop:'0.5rem'}} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <Typography variant="h5">Hello {getFirstName(patientDashboard?.name)}!</Typography>
                        <Typography variant="h6" className="mt-3">Upcoming appointment</Typography>
                        <div className="d-flex align-items-center mt-2">
                            <CalendarTodayIcon />
                            <Typography variant="body1" className="ms-2">
                            Monday, January 3, 2023 8:30 AM
                            </Typography>
                        </div>
                        <Typography variant="body1" className="mt-2">
                            Follow up #7 with Laura at {patientDashboard?.clinic?.name}
                        </Typography>
                        <Typography variant="body2" className="mt-3">
                            We recommend that you complete all assigned survey(s) before arriving to the clinic.
                        </Typography>
                        <div style={{height: '1px',background:'linear-gradient(to right,  transparent, #ccc,#ccc,#ccc,  #ccc, transparent)', marginBottom:'0.5rem', marginTop:'0.5rem'}} />
                        <Typography variant="body2" className="mt-3">
                            Need to reschedule or cancel?
                        </Typography>
                    </Col>
                    <Col>
                        <Button variant="warning" className="mt-3 float-right text-white">Add To Calendar</Button>
                    </Col>
                </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const avatarStyle = {
  backgroundColor: '#007bff',
  color: 'white',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '10px'
};

export default AppointmentCard;
