import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaCopy, FaPen, FaPlusSquare, FaTrash, FaTrashAlt } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { deleteSurveyById, duplicateSurveyById, saveSurvey } from '../../utils/apiSurvey';
import DeleteModal from '../modal/DeleteModal';
import { useDispatch, useSelector } from '../../redux/store';
import { getAllSurveySettings, getDirectorAllSurvey, getPaAllSurvey } from '../../redux/slices/survey';
import { CONSTANT } from '../../utils/constant';

const SurveyTable = () => {
  const [surveys, setSurveys] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { surveyList } = useSelector((state)=>state.survey);

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    console.log(user);
    if(user){
      if(user.role === CONSTANT.USER_ROLE.SAAS){
        dispatch(getAllSurveySettings());
      }
      if(user.role === CONSTANT.USER_ROLE.PROGRAM_ADMIN){
        dispatch(getPaAllSurvey());
      }
      if(user.role === CONSTANT.USER_ROLE.Director){
        dispatch(getDirectorAllSurvey());
      }
    }
  }, [dispatch]);

  useEffect(()=>{
    setSurveys(surveyList);
  },[surveyList]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const deleteSurvey = async (surveyId) => {
    const results = await deleteSurveyById(surveyId);
    setOpenDeleteModal(false)
    // this line to handle notification after
    console.log(results)
    navigate(0);
  }

  const duplicateSurvey = async (surveyId) => {
    const results = await duplicateSurveyById(surveyId);
    setSurveys([]);
    // this line to handle notification after
    console.log(results)
  }

  const onClickAddSurvey = async () => {
    const results = await saveSurvey({
      title: "test",
      description: "test"
    });
    if (results._id) {
      navigate(`/dashboard/new-survey/${results._id}`);
    }
  };

  const calculateNumberSurveys = (data) => {
    let sum = 0;
    data.pages.forEach(page => {
      const d = page.elements.filter(d=>d.type !== 'html');
      sum += d.filter(d=>d.type !== 'panel' || (d.type === 'panel' && d.showNumber === true)).length;
      d.forEach(element => {
        if (element.type === 'panel') {
          sum += element.elements.filter(e=>e.type !== 'html').length;
        }
      });
    });
    return sum;
  }

  return (
    <div>
      <Card>
        <div
          className="bg-dark px-3"
          style={{
            paddingTop: '15px',
            paddingBottom: '1px',
            background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
          }}
        >
          <Table striped="rows" className="clinic-table survey-table">
            <thead className="rounded-2" style={{}}>
              <tr className="py-0 m-2 bg-white rounded-2">
                <th>Survey Title</th>
                <th>Survey Description</th>
                <th>Status</th>
                <th>Last Updated</th>
                <th>No. of Questions</th>
                <th>Actions</th>
              </tr>
            </thead>
          </Table>
        </div>
        <div>
          <Table striped="rows" className="clinic-table survey-table">
            <tbody>
              {
                surveys.map((item, key) => (
                  item ? (
                  <tr key={key}>
                    <td>
                      <Link to={`/dashboard/edit-survey/${item._id}`}>{item.title}</Link>
                    </td>
                    <td>{item.description}</td>
                    <td>{item.status}</td>
                    <td>{item.updatedAt ? <>{format(new Date(item.updatedAt), "MM/dd/yyyy")}</>:<></>}</td>
                    <td>{calculateNumberSurveys(item)}</td>
                    {console.log(item.pages)}
                    <td>
                      <Nav>
                        <NavDropdown
                          title={<FaPlusSquare className="text-primary" style={{ boxShadow: '  -3px 3px' }} />}
                          className="surveyDropdowm"
                        >
                          <NavDropdown.Item href={`/dashboard/edit-survey/${item._id}`} className="dropDownItem">
                            <Link to={`/dashboard/edit-survey/${item._id}`} style={{ color: '#505050' }}>
                              <div
                              style={{
                                background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                                padding: '0px 5px 2px',
                                borderRadius: '50%',
                                textAlign: 'center',
                                float: 'left',
                                marginRight: '5px'
                              }}>
                                <FaPen style={{fill: '#fff', fontSize: '11px'}}/>
                              </div>
                                Edit
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => duplicateSurvey(item._id)} className="dropDownItem">
                          <div
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '0px 5px 2px',
                            borderRadius: '50%',
                            textAlign: 'center',
                            float: 'left',
                            marginRight: '5px'
                          }}>
                            <FaCopy style={{fill: '#fff', fontSize: '11px'}}/>
                          </div>
                            Duplicate
                          </NavDropdown.Item>
                          <DeleteModal
                            dltModalTxt={"Are you sure you want to remove the survey"}
                            show={openDeleteModal}
                            onHide={() => setOpenDeleteModal(false)}
                            onDelete={() => deleteSurvey(item._id)}
                          />
                          <NavDropdown.Item onClick={() => setOpenDeleteModal(true)} className="dropDownItem">

                            <div
                            style={{
                              background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                              padding: '0px 5px 2px',
                              borderRadius: '50%',
                              textAlign: 'center',
                              float: 'left',
                              marginRight: '5px'
                            }}>
                              <FaTrash style={{fill: '#fff', fontSize: '11px'}}/>
                            </div>
                              Delete
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>
                    </td>
                  </tr>
                  ) : (<></>)
                ))
              }


            </tbody>
          </Table>

        </div>

        <Row>
          <Col className="px-4 text-end mt-5 mb-4">
            {user.role === CONSTANT.USER_ROLE.SAAS && <Button
              className="btn btn-primary"
              onClick={() => onClickAddSurvey()}
              style={{
                background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                padding: '13px 25px',
                border: 'none',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
              }}
            >
              Add New Survey
            </Button> }
          </Col>
        </Row>
      </Card>

    </div>
  );
};

export default SurveyTable;