import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Row, Col, Image } from 'react-bootstrap';
import { Container, Box } from '@mui/material';
import ResetPasswordForm from '../sections/auth/login/ResetPasswordForm';
import OTPForm from '../sections/auth/login/OTPForm';

import MapsLogo from '../assets/images/MAPS_logo.svg';
import { useDispatch, useSelector } from '../redux/store';
import { verifyOTP } from '../redux/slices/resetPassword';
import * as toaster from '../utils/toaster';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
    textAlign: 'center'
}));


// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { otp } = useSelector((state)=> state.resetPassword);

    const handleSubmit = async () => {
        const res = await dispatch(verifyOTP());
        if(res.status){
            toaster.success("Password Reset Successfully!");
            navigate("/");
        }
        else{
            toaster.error("Error");
        }
    }
    return (
        <>
            <Helmet>
                <title> MAPS | Measurement Assisted Practice System </title>
            </Helmet>

            <StyledRoot>
                <Container className="mt-5" maxWidth='sm'>
                    <Row className="justify-content-md-center">
                        <Col>
                            <Box textAlign="center" mb={4}>
                                <Image style={{
                                            width: "266px",
                                            height: "54px",
                                            display: 'block',
                                            margin: '0 auto'
                                        }} src={MapsLogo} alt='logo maps' />
                            </Box>
                            
                            {otp && otp !== '' ?
                            <ResetPasswordForm submit={handleSubmit} /> : <OTPForm />}
                        </Col>
                    </Row>
                </Container>
            </StyledRoot>
        </>
    );
}
