import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, InputAdornment } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import { FaUser } from 'react-icons/fa';
import DeleteModal from '../modal/DeleteModal';
import PasswordResetModal from '../modal/PasswordResetModal';
import SaveChangesModal from '../modal/SaveChangesModal';
import PasswordChangeModal from '../modal/PasswordChangeModal';
import { useDispatch, useSelector } from '../../redux/store';
import SendCenterDirectorInvitationModal from '../modal/SendCenterDirectorInvitationModal';
import { deleteDirector } from '../../redux/slices/programAdmin';
import { resendEmail } from '../../redux/slices/user';

function CenterDirectorForm() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { center } = useSelector((state)=> state.programAdmin);

  const [modalShow, setModalShow] = React.useState(false);
  const [openResetModal, setOpenResetModal] = React.useState(false);
  const [openInvitaionModal, setOpenInvitaionModal] = React.useState(false);
  const [saveChnagesModal, setSaveChnagesModal] = React.useState(false);
  const [passwordSuccessfullModal, setPasswordSuccessfullModal] = React.useState(false);
  const [data, setData] = useState({
    name: '',
    phoneNumber: '',
    email: ''
  });

  useEffect(()=>{
    if(center && center?.director){
      setData({
        name: center?.director.name,
        email: center?.director.email
      })
    }
  },[center])

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here, e.g., send data to server or perform actions
    console.log('Form data submitted:', data);
  };

  const handleDelete = async () => {
    await dispatch(deleteDirector(center?.director.id));
    setModalShow(false);
    navigate(0);
  }

  const sendEmail = () => {
    dispatch(resendEmail(center?.director.email));
  }

  return (
    <>
      <Row>
        <Col md={3}>
          <div className="assignDirectorBtn">
            <span>
              <PersonIcon />
            </span>
            <button type="button" style={{display: center?.director === null ? "block":"none"}} onClick={() => setOpenInvitaionModal(true)}>
              + Assign Director
            </button>
            <button type='button' style={{display: center?.director?.name && center?.director['Invitation Accepted'] === false ? "block":"none"}} onClick={sendEmail}>
              Resend Email
            </button>
          </div>
        </Col>
        <Col md={9} style={{display: center?.director === null ? "none":"block"}}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  disabled
                  name="name"
                  variant="outlined"
                  value={data.name}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaUser />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  disabled
                  name="lastName"
                  variant="outlined"
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaUser />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid> */}

              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  disabled
                  name="email"
                  variant="outlined"
                  value={data.email}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
          <p style={{ color: '#B1B1B1', marginTop: '30px' }}>Clinic Assigned </p>
          <TextField
            fullWidth
            name="phone"
            variant="outlined"
            value="West End clinic"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaBuilding className="text-primary" />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid> */}
            </Grid>

            {/* <Button type="submit" variant="contained" color="primary">
        Submit
      </Button> */}
          </form>
        </Col>
      </Row>
      <Row className=" align-items-end" style={{ marginTop: '120px' }}>
      {center?.director?.name && center?.director['Invitation Accepted'] === false &&
        <Col xs={12}>
          <small className="text-secondary" style={{ fontWeight: '500' }}>
            Invitation Request Pending.
          </small>
        </Col>
      }
        <Col xs={3}>
          <Link
            className="btn btn-outline-secondary me-3"
            to="/dashboard/center"
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
              padding: '13px 25px',
              fontSize: '13px',
            }}
          >
            Back
          </Link>
        </Col>
        <Col className="text-end" xs={9}>
          <Button
            type="button"
            onClick={() => setModalShow(true)}
            variant="contained"
            className="me-3 btn btn-outline-danger"
            style={{
              padding: '13px 25px',
            }}
          >
            Delete
          </Button>
          {/* <Button
            type="submit"
            onClick={() => setOpenResetModal(true)}
            variant="contained"
            className="me-3"
            style={{
              background: '#6A6A6A',
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            }}
          >
            Reset Password
          </Button> */}
          <Button
            type="submit"
            variant="contained"
            style={{
              background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            }}
          >
            Save Changes
          </Button>
        </Col>
      </Row>
      <DeleteModal
        show={modalShow}
        dltModalTxt={'Are you sure you want to delete this director'}
        onHide={() => setModalShow(false)}
        onDelete={()=> handleDelete()}
      />
      <PasswordResetModal show={openResetModal} onHide={() => setOpenResetModal(false)} />
      <SendCenterDirectorInvitationModal show={openInvitaionModal} onHide={() => setOpenInvitaionModal(false)} />
      <SaveChangesModal show={saveChnagesModal} onHide={() => setSaveChnagesModal(false)} />
      <PasswordChangeModal show={passwordSuccessfullModal} onHide={() => setPasswordSuccessfullModal(false)} />
    </>
  );
}

export default CenterDirectorForm;
