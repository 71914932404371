import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Card, Modal, Button, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import { FaPen, FaTrash, FaUser } from 'react-icons/fa';
import DeleteModal from '../modal/DeleteModal';
import { useDispatch, useSelector } from '../../redux/store';
import { getPatient } from '../../redux/slices/centerUser';

const CenterUserTable = ({search}) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [tableRow,setTableRow] = React.useState([]);

  const dispatch = useDispatch();
  const { patientList, meta:metaCenter } = useSelector((state)=> state.centerUser);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [meta,setMeta] = useState({});

  useEffect(() => {
    dispatch(getPatient(page,rowsPerPage));
  }, [dispatch]);

  useEffect(()=>{
    if(search === ''){
      setTableRow(patientList);
    }
    else{
      setTableRow(patientList.filter(x=>x.name.toLowerCase().includes(search.toLowerCase())));
    }
    setTotalCount(metaCenter.totalItems);
  },[patientList,search]);

  
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    const p = newPage + 1;
    setPage(newPage);
    dispatch(getPatient(p,rowsPerPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    const p = 1;
    setRowsPerPage(event.target.value);
    dispatch(getPatient(p,event.target.value));
  };

  return (
    <>
    <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'id'}
                      direction={orderBy === 'id' ? order : 'asc'}
                      onClick={() => handleRequestSort('id')}
                    >
                      Id
                    </TableSortLabel>
                  </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={() => handleRequestSort('name')}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'date'}
                        direction={orderBy === 'date' ? order : 'asc'}
                        onClick={() => handleRequestSort('date')}
                      >
                        Added Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={{textAlign:'center'}}>
                        Actions
                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRow && tableRow.map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      {row.uniqueId}
                    </TableCell>
                    <TableCell>
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {row.createdAt}
                    </TableCell>
                    <TableCell>
                      <Link to={`/dashboard/edit-center-user/${row.id}`} style={{ textDecoration: 'none', color: '#000' }}>
                        <div
                        style={{
                          background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                          padding: '0px 5px 2px',
                          borderRadius: '50%',
                          textAlign: 'center',
                          float: 'left',
                        }}>
                          <FaPen style={{fill: '#fff', fontSize: '11px'}}/>
                        </div>
                        &nbsp; Manage
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          
    </Paper>
    </>
  );
};

export default CenterUserTable;
