import React from 'react'
import { useParams } from 'react-router-dom'
import { Card, Col, Container, Row, Button } from 'react-bootstrap'
import AddCenterForm from '../components/form/AddCenterForm'

const AddCenter = () => {
    const { name } = useParams();
  return (
    <>
   <Container> 
    <Row className='justify-content-center'>
        <Col md={10}>
            <Card className=' shadow-sm px-5 pt-5 pb-3 ' border='0'>
                <Row>
                    <Col>
                        <h2 className='text-secondary'>Add Center</h2>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col>
                        <AddCenterForm />
                    </Col>
                </Row>
            </Card>  
        </Col>
    </Row>
    </Container>
   </>
  )
}

export default AddCenter;
