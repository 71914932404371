import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaLock, FaLockOpen, FaPen, FaTrash, FaUser, FaUserEdit } from 'react-icons/fa';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import ToggleButton from 'react-toggle-button';
import { format } from 'date-fns';
import { useDispatch, useSelector } from '../../redux/store';
import { getUserList } from '../../redux/slices/user';

const UserListTable = ({search}) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(false);

  const [users,setUsers] = useState([]);
  
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const handleToggle = () => {
    setValue(!value);
  };

  const { userList, meta } = useSelector((state)=> state.user);

  useEffect(()=>{
    dispatch(getUserList(page+1,rowsPerPage));
  },[page,rowsPerPage]);

  useEffect(()=>{
    setUsers(userList);
  },[userList]);

  useEffect(()=>{
    if(search !== null && search !== ''){
      setUsers(userList.filter(x=> x.name.toLowerCase().includes(search.toLowerCase()) ||
        x.email.toLowerCase().includes(search.toLowerCase())
      ));
    }
    else{
      setUsers(userList);
    }
  },[search]);

  useEffect(()=>{
    if(meta){
      setTotalRows(meta.totalItems);
      setRowsPerPage(meta.itemsPerPage);
    }
  },[meta])

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = [...users].sort((a, b) => {
    if (orderBy) {
      return order === 'asc'
        ? a[orderBy] < b[orderBy] ? -1 : 1
        : a[orderBy] > b[orderBy] ? -1 : 1;
    }
    return 0;
  });

  return (
    <>
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleRequestSort('name')}
                  >
                  Username
                </TableSortLabel>
              </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'email'}
                    direction={orderBy === 'email' ? order : 'asc'}
                    onClick={() => handleRequestSort('email')}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'createdAt'}
                    direction={orderBy === 'createdAt' ? order : 'asc'}
                    onClick={() => handleRequestSort('createdAt')}
                  >
                    User Created
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'adminStatus'}
                    direction={orderBy === 'adminStatus' ? order : 'asc'}
                    onClick={() => handleRequestSort('adminStatus')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{textAlign:'center'}}>
                    Actions
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData && sortedData.map((row, index) => (
              <TableRow hover key={index}>
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell>
                  {row.email}
                </TableCell>
                <TableCell>
                  {format(new Date(row.createdAt), "MM/dd/yyyy")}
                </TableCell>
                <TableCell>
                  <div className="d-flex justify-content-center align-items-center pt-1">
                    <ToggleButton
                      value={row.adminStatus}
                      onToggle={handleToggle}
                    />
                  </div>
                </TableCell>
                <TableCell>
                  <Link to={`/dashboard/UserEdit/${row.id}`}>
                    <div>
                      <div
                      style={{
                        background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                        padding: '0px 5px 2px',
                        borderRadius: '50%',
                        textAlign: 'center',
                        float: 'left'
                      }}>
                        <FaPen style={{fill: '#fff', fontSize: '11px'}}/>
                      </div>
                      &nbsp;
                      <span className="text-secondary" style={{ fontSize: '12px' }}>
                        Edit
                      </span>{' '}
                    </div>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRows}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </>
  );
};

export default UserListTable;
