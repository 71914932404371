import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/interceptor';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    organization: {},
    organizationList: [],
    organizationMeta: {},
    saasDashboardData: {},
    saasDashboardChartData : {}
  };


  const slice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      addOrganizationSuccess(state){
        state.isLoading = false;
      },
    
      getOrganizationSuccess(state, action) {
        state.isLoading = false;
        state.organizationList = action.payload.items;
        state.organizationMeta = action.payload.meta;
        state.organization = {};
      },
    
      getSaasDashboardSuccess(state, action) {
        state.isLoading = false;
        state.saasDashboardData = action.payload;
      },

      getOrganizationIdSuccess(state, action) {
        state.isLoading = false;
        state.organization = action.payload;
      },

      getSaasDashboardChartSuccess(state, action){
        state.isLoading = false;
        state.saasDashboardChartData = action.payload;
      }

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  export function addOrganization(data){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/saas/create-organization', data);
        dispatch(slice.actions.addOrganizationSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function updateOrganization(id,data){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/saas/organization/${id}`, data);
        dispatch(slice.actions.addOrganizationSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function getOrganizationList(page,pageSize) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/saas/organization?page=${page}&take=${pageSize}`);
        dispatch(slice.actions.getOrganizationSuccess(response.data.data));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };

  export function getOrganizationListByFilter(status,licenseType,page,pageSize) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        let data = `?page=${page}&take=${pageSize}&filter`;
        data += status !== '' ? `&status=${status}` : "";
        data += licenseType !== '' ? `&type=${licenseType}` : "";
        const response = await axios.get(`/saas/organization${data}`);
        dispatch(slice.actions.getOrganizationSuccess(response.data.data));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };

  export function getOrganizationById(id) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/saas/organization?organizationId=${id}`);
        dispatch(slice.actions.getOrganizationIdSuccess(response.data.data?.items.find(x=>x.id === id)));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };

  export function removeOrganization(id){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.delete(`/saas/organization/${id}`);
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function deactivateOrganization(id,status){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.put(`/saas/organization/${id}/status?status=${status}`);
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function addOrganizationAdmin(data){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/saas/create-admin', data);
        dispatch(slice.actions.addOrganizationSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function updateOrganizationAdmin(id,data){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/saas/organization/${id}/updateAdmin`, data);
        dispatch(slice.actions.addOrganizationSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  export function removeOrganizationAdmin(id){
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.delete(`/saas/admin/${id}`);
        dispatch(slice.actions.addOrganizationSuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };
  
  export function getSaasDashboardData() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/saas/saas-dashboard');
        dispatch(slice.actions.getSaasDashboardSuccess(response.data.data));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  };

  export function getProgramAdminDashboard(){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/program-admin/get-admin-dashboard');
        dispatch(slice.actions.getSaasDashboardSuccess(response.data.data));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  }

  export function getDirectorDashboard(){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/director/get-clinic-dashboard');
        dispatch(slice.actions.getSaasDashboardSuccess(response.data.data));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  }

  export function getDashboardChartData(){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/user/dashboard-charts');
        dispatch(slice.actions.getSaasDashboardChartSuccess(response.data.data));
        // console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    };
  }