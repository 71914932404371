import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Card, Tabs, Tab } from 'react-bootstrap';
import ClinicDirectForm from '../components/form/ClinicDirectForm';
import DcRobForm from '../components/form/DcRobForm';
import EditOrganizationForm from '../components/form/EditOrganizationForm';
import { getOrganizationById } from '../redux/slices/organization';
import { useDispatch, useSelector } from '../redux/store';
import OrganizationAdminForm from '../components/form/OrganizationAdminForm';
import OrganizationClinicTable from '../components/tables/OrganizationClinicTable';

const EditOrganization = () => {

  const dispatch = useDispatch();
  
  const { organization } = useSelector((state)=> state.organization);

  const {id} = useParams();

  useEffect(() => {
    if(id){
      dispatch(getOrganizationById(id));
    }
  }, [dispatch,id]);

  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <Card className=" shadow-sm px-5 pt-5 pb-3 " border="0">
              <Row>
                <Col>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h4 className="text-secondary">Edit Organization</h4>
                    <span
                      style={{
                        display: 'inline-block',
                        // color: '#FF0000',
                        // backgroundColor: '#FF00001C',
                        color: '#03bf10',
                        background: '#e1ffe3',
                        borderRadius: '25px',
                        padding: '5px 10px',
                        fontSize: '10px',
                        marginLeft: '10px',
                      }}
                    >
                      Active
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Row>
                    <Col md={12}>
                      <div className="formTabs">
                        <Tabs
                          defaultActiveKey="organizationForm"
                          transition={false}
                          id="noanim-tab-example"
                          className="mb-3 mx-3 bg-white border-muted tab"
                          style={{
                            borderTop: '1px solid #ccc',
                            borderLeft: '1px solid #ccc',
                            borderRight: '1px solid #ccc',
                            borderBottom: '1px solid #ccc',
                          }}
                        >
                          <Tab eventKey="organizationForm" title={<span> Organization Details</span>}>
                            <EditOrganizationForm />
                          </Tab>
                          <Tab eventKey="directorForm" title={<span> Admin</span>}>
                            <OrganizationAdminForm />
                          </Tab>
                          <Tab eventKey="centerForm" title={<span> Centers ({organization?.clinics?.length})</span>}>
                            <OrganizationClinicTable />
                          </Tab>
                        </Tabs>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditOrganization;
