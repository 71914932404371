import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import * as toaster from '../../../utils/toaster';
import { useDispatch } from '../../../redux/store';
import { addHash, resetPassword } from '../../../redux/slices/resetPassword';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [email,setEmail] = useState('');
  const [modal, setModal] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleEmail = async (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = async () =>{
    const data = await dispatch(resetPassword(email));
    if(data.status){
      dispatch(addHash(data.data.otphash));
      toaster.success(data.message);
      navigate(`/reset-password/${data.data.otphash}`)
    }
    else{
      toaster.error(data.message || data.data.message || "Error");
    }
  }

  const onSubmit = async (data) => {
    const {email, password} = data;
    setLoading(true);
    if (!email || !password) {
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, { email, password });
      if (response.data.status) {
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.data.user));
        navigate('/dashboard', { replace: true });
      }
      else {
        toaster.error(response.data.message || 'Password or email not valid');
      }
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <Box component="form"
    onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>    
        <TextField name="email" label="Email address" 
        {...register('email', { 
          required: 'Email is required', 
          pattern: {
            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            message: 'Invalid email address'
          }
        })}
        error={!!errors.email}
        helperText={errors.email ? errors.email.message : ''}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('password', { 
            required: 'Password is required', 
            // minLength: {
            //   value: 8,
            //   message: 'Password must be at least 8 characters long'
            // },
            // pattern: {
            //   value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
            //   message: 'Password must contain at least one letter and one number'
            // }
          })}
          error={!!errors.password}
          helperText={errors.password ? errors.password.message : ''}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel control={<Checkbox name="remember" label="Remember me" />} label="Remember me" />
        <Link variant="subtitle2" underline="hover" onClick={()=> setModal(true)}>
          Reset Password?
        </Link>
      </Stack>

      <Modal show={modal} onHide={()=>setModal(false)}>
        <Modal.Body>
          <TextField name='email' placeholder='Enter your email' style={{marginBottom: "1rem"}} fullWidth onChange={(e)=>handleEmail(e)} />
            <LoadingButton fullWidth size="large" type="button" variant="contained" onClick={handleForgotPassword}>
              Reset Password
            </LoadingButton>
        </Modal.Body>
      </Modal>

      <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained">
        Login
      </LoadingButton>
    </Box>
  );
}
