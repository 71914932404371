// component
import { CONSTANT } from '../../../utils/constant';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
    role: [CONSTANT.USER_ROLE.SAAS,CONSTANT.USER_ROLE.PROGRAM_ADMIN,CONSTANT.USER_ROLE.Director,CONSTANT.USER_ROLE.Patient]
  },
  {
    title: 'Organization',
    path: '/dashboard/organization',
    icon: icon('Group_1710'),
    role: [CONSTANT.USER_ROLE.SAAS]
  },
  {
    title: 'Center User',
    path: '/dashboard/center-user',
    icon: icon('Group_1710'),
    role: [CONSTANT.USER_ROLE.Director,CONSTANT.USER_ROLE.Staff,CONSTANT.USER_ROLE.Clinician]
  },
  {
    title: 'Centers',
    path: '/dashboard/',
    icon: icon('Group_1710'),
    role: [CONSTANT.USER_ROLE.PROGRAM_ADMIN],
    children: [
      {
        title: 'Center List',
        path: '/dashboard/center',
        icon: icon(''),
      },
      // {
      //   title: 'Directors',
      //   path: '/dashboard/director',
      //   icon: icon(''),
      // },
    ],
  },
  {
    title: 'Survey Management',
    path: '/dashboard/appoinment',
    icon: icon('appointment'),
    role: [CONSTANT.USER_ROLE.SAAS,CONSTANT.USER_ROLE.PROGRAM_ADMIN,CONSTANT.USER_ROLE.Director],
    children: [
      {
        title: 'Surveys',
        path: '/dashboard/survey',
        icon: icon(''),
      },
      // {
      //   title: 'Outcome Measures',
      //   path: '/dashboard/OutcomeMeasureCreateTable',
      //   icon: icon(''),
      // },
    ],
  },
  // {
  //   title: 'Reports',
  //   path: '/dashboard/',
  //   icon: icon('report'),
  //   role: [],
  //   children: [
  //     {
  //       title: 'Analysis',
  //       path: '/dashboard/comparativeAnalysis',
  //       icon: icon(''),
  //     },
  //   ],
  // },
  // {
  //   title:'Comparative Analysis',
  //   path: '/dashboard/ComparativeAnalysis',
  //   icon: icon('ic_appointments'),
  // },
  // {
  //   title: 'Reports',
  //   path: '/dashboard/Charts',
  //   icon: icon('ic_reports'),
  // },
  {
    title: 'System Setup',
    path: '/dashboard/',
    icon: icon('surveys'),
    role: [CONSTANT.USER_ROLE.SAAS],
    children: [
      {
        title: 'User Lists',
        path: '/dashboard/UserList',
        icon: icon(''),
      },
      {
        title: 'User Permission',
        path: '/dashboard/user-permission',
        icon: icon(''),
      },
      // {
      //   title: 'User Logs',
      //   path: '/dashboard/UserLog',
      //   icon: icon(''),
      // },
    ],
  },

  // {
  //   title: 'Survey Result',
  //   path: '/dashboard/blog',
  //   icon: icon(''),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;