import React, { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Typography, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle } from '@mui/icons-material';

const CreateAccountForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    digit: false,
    letter: false,
  });
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handlePasswordChange = (e) => {
    const pass = e.target.value;
    setPassword(pass);
    setPasswordCriteria({
      length: pass.length >= 8,
      digit: /\d/.test(pass),
      letter: /[a-zA-Z]/.test(pass),
    });
  };

  const isFormValid = passwordCriteria.length && passwordCriteria.digit && passwordCriteria.letter && termsAccepted;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;
    console.log({email, password});
  };

  return (
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              {passwordCriteria.length ? <CheckCircle color="success" /> : <CancelIcon color="error" />}
              <Typography variant="body2" sx={{ ml: 1 }}>
                Has at least 8 characters?
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              {passwordCriteria.digit ? <CheckCircle color="success" /> : <CancelIcon color="error" />}
              <Typography variant="body2" sx={{ ml: 1 }}>
                Has one digit?
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              {passwordCriteria.letter ? <CheckCircle color="success" /> : <CancelIcon color="error" />}
              <Typography variant="body2" sx={{ ml: 1 }}>
                Has one letter?
              </Typography>
            </Box>
          </Box>
          <FormControlLabel
            control={<Checkbox value={termsAccepted} color="primary" onChange={() => setTermsAccepted(!termsAccepted)} />}
            label="I agree to the terms & conditions"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={!isFormValid}
          >
            Create Account
          </Button>
        </Box>
  );
};

export default CreateAccountForm;
