import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Modal } from 'react-bootstrap';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {  CardMedia, Button } from '@mui/material';

const SaveChangesModal = (props) => {
//   const [modalShow, setModalShow] = React.useState(false);

  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-4 text-center">
        <div className="text-center">
         <div className='checkMarkIcon'>
            <CheckCircleOutlineIcon style={{ fontSize: '12rem' }} />
         </div>
        </div>
        <h4 className="text-muted text-center mt-4">Changes Saved</h4>
      </Modal.Body>
    </Modal>
  );
};

export default SaveChangesModal;
