import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { CardMedia } from '@mui/material'
import CreateSurveyForm from '../../components/form/CreateSurveyForm'

const CreateSurvey = () => (
    <div>
      <Container>
        <Row>
          <Col>
            <Card className=' shadow-sm px-0 pt-3' border='0' style={{overFlow:'hidden'}}>
                <Row>
                    <Col md={6} className='text-center pt-5 ps-5'  style={{height:'450px'}}>
                      <CardMedia
                        component="img"
                        fluid
                        image="/assets/illustrations/Group523.png"
                        alt="Live from space album cover"

                        style={{height:'350px',width:'350px',position:'absolute'}}
                        />
                        <div  style={{height:'100px', background:'#F6F6F6',position:'absolute'}}/>
                    </Col>
                    
                    <Col md={6}> 
                      <h4 className='text-secondary'>Create Survey</h4>
                      <CreateSurveyForm />
                    </Col>
                </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );

export default CreateSurvey
