import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import emojiImg from '../../assets/images/emoji.png';

const ShortText = () => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <Form className="radio-tabs">
        <Row className='mt-4'>
          <Col xs={12}>
            <div className="shortTxtInput">
              <input type="text" placeholder="Short text" />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ShortText;
