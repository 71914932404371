import { Navigate, useRoutes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import ClinicList from './pages/ClinicList';
import AddClinic from './pages/AddClinic';
// import SurveyPage from './pages/SurveyPage';
import AppoinmentPage from './pages/appointment';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import EditClinic from './pages/editform/EditClinic';
import WestEndClinic from './pages/editform/WestEndClinic';
import Director from './pages/Director';
import DirectorEdit from './pages/directorEdit/DirectorEdit';
import Survey from './pages/Survey';
import CreateSurvey from './pages/survey/CreateSurvey';
import SurveyTabs from './pages/survey/SurveyTabs';
import CreateQuestion from './pages/question/CreateQuestion';
import UpdateQuestions from './pages/question/UpdateQuestions';
import GAD from './pages/question/GAD';
import SkipLogic from './pages/question/SkipLogic';
import OutcomeMeasure from './pages/OutcomeMeasure';
import OutcomeMeasureTable from './pages/outcome/OutcomeMeasureTable';
import OutcomeMeasureCreateTable from './pages/outcome/OutcomeMeasureCreateTable';
import OutcomeMeasureQuestion from './pages/question/OutcomeMeasureQuestion';
import ScoringOption from './pages/ScoringOption';
import ComparativeAnalysis from './pages/ComparativeAnalysis';
import UserCreation from './pages/user/UserCreation';
import UserLog from './pages/user/UserLog';
import UserList from './pages/user/UserList';
import UserPermissions from './pages/user/UserPermissions';
import Charts from './pages/Charts';
import BarChart from './pages/chart/BarChart';
import LineChart from './pages/chart/LineChart';
import UserPermissionPage from './pages/user/UserPermissionPage';
import AddScrore from './pages/question/AddScrore';
import ProtectedRoute from './utils/ProtectedRoute';
import UserLogin from './utils/UserLogin';
import OrganizationList from './pages/OrganizationList';
import AddOrganization from './pages/AddOrganization';
import EditOrganization from './pages/EditOrganization';
import NewSurvey from './pages/NewSurvey';
import ResetPasswordPage from './pages/ResetPasswordPage';
import StafSurvey from './pages/StafSurvey';
import SetPasswordPage from './pages/SetPasswordPage';
import CreateAccountPage from './pages/CreateAccount';
import CenterList from './pages/CenterList';
import AddCenter from './pages/AddCenter';
import EditCenter from './pages/EditCenter';
import CenterUserList from './pages/CenterUserList';
import AddCenterUser from './pages/AddCenterUser';
import PublicSurvey from './pages/PublicSurvey';
import ChangePasswordForm from './sections/auth/login/ChangePasswordForm';

// ----------------------------------------------------------------------

export default function Router() {
  <Helmet>
    <title> MAPS | Measurement Assisted Practice System </title>
  </Helmet>
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'change-password', element: <ChangePasswordForm/> },
        // { path: 'survey', element: <SurveyPage /> },
        { path: 'appoinment', element: <AppoinmentPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'ClinicList', element: <ClinicList /> },
        { path: 'AddClinic', element: <AddClinic /> },
        { path: 'EditClinic', element: <EditClinic /> },
        { path: 'WestEndClinic', element: <WestEndClinic /> },
        { path: 'Director', element: <Director /> },
        { path: 'DirectorEdit', element: <DirectorEdit /> },
        { path: 'Survey', element: <Survey /> },
        { path: 'CreateSurvey', element: <CreateSurvey /> },
        { path: 'SurveyTabs', element: <SurveyTabs /> },
        { path: 'CreateQuestion', element: <CreateQuestion /> },
        { path: 'UpdateQuestions', element: <UpdateQuestions /> },
        { path: 'GAD', element: <GAD /> },
        { path: 'SkipLogic', element: <SkipLogic /> },
        { path: 'OutcomeMeasure', element: <OutcomeMeasure /> },
        { path: 'OutcomeMeasureTable', element: <OutcomeMeasureTable /> },
        { path: 'OutcomeMeasureCreateTable', element: <OutcomeMeasureCreateTable /> },
        { path: 'OutcomeMeasureQuestion', element: <OutcomeMeasureQuestion /> },
        { path: 'ScoringOption', element: <ScoringOption /> },
        { path: 'ComparativeAnalysis', element: <ComparativeAnalysis /> },
        { path: 'UserCreation', element: <UserCreation /> },
        { path: 'UserEdit/:id', element: <UserCreation/> },
        { path: 'UserList', element: <UserList /> },
        { path: 'UserLog', element: <UserLog /> },
        { path: 'UserPermissions', element: <UserPermissions /> },
        { path: 'UserPermissionsEdit/:id', element: <UserPermissions /> },
        { path: 'Charts', element: <Charts /> },
        { path: 'BarChart', element: <BarChart /> },
        { path: 'LineChart', element: <LineChart /> },
        { path: 'user-permission', element: <UserPermissionPage /> },
        { path: 'AddScore', element: <AddScrore /> },
        { path: 'organization', element: <OrganizationList /> },
        { path: 'add-organization/:name', element: <AddOrganization /> },
        { path: 'edit-organization/:id', element: <EditOrganization /> },
        { path: 'new-survey/:surveyId', element: <NewSurvey /> },
        { path: 'edit-survey/:surveyId', element: <NewSurvey /> },
        { path: 'staf-survey/:surveyId', element: <StafSurvey /> },
        { path: 'center', element: <CenterList/> },
        { path: 'add-center', element: <AddCenter/> },
        { path: 'edit-center/:id', element: <EditCenter/> },
        { path: 'center-user', element: <CenterUserList/> },
        { path: 'add-center-user', element: <AddCenterUser/> },
        { path: 'edit-center-user/:id', element: <AddCenterUser/> }
      ],
    },
    {
      path: 'login',
      element: (
        <UserLogin>
          <LoginPage />
        </UserLogin>),
    },
    {
      path: 'sign-up',
      element: (
        <UserLogin>
          <CreateAccountPage />
        </UserLogin>),
    },
    {
      path: 'survey/:token',
      element: (<PublicSurvey/>)
    },
    {
      path: 'set-password/:token',
      element: (<UserLogin>
        <SetPasswordPage />
      </UserLogin>)
    },
    {
      path: 'reset-password/:hash',
      element: (<UserLogin>
        <ResetPasswordPage />
      </UserLogin>)
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
