import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, InputAdornment } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import {
  FolderZip,
  LocalHospitalOutlined,
  LocalHospitalTwoTone,
  LocationCity,
  LocationCityOutlined,
  LocationCityTwoTone,
} from '@mui/icons-material';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/interceptor';
import { useDispatch, useSelector } from '../../redux/store';
import { addOrganization } from '../../redux/slices/organization';
import { addCenterUser, deleteCenterUser, getPatientById, updateCenterUser } from '../../redux/slices/centerUser';
import DeleteModal from '../modal/DeleteModal';

function AddCenterUserForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { patient } = useSelector((state)=>state.centerUser);

  const [isEdit,setIsEdit] = useState(false);

  const { id } = useParams();

  const [modalShow, setModalShow] = useState(false);

  useEffect(()=>{
    if(id){
      setIsEdit(true);
    }
  },[id]);

  useEffect(()=>{
    if(isEdit){
      dispatch(getPatientById(id));
    }
  },[isEdit]);

  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneNumber: '',
    dateOfBirth: '',
    clinicianId: '1'
  });

  useEffect(()=>{
    if(isEdit){
      setData(patient);
    }
  },[isEdit,patient]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeNumber = (event) => {
    const { name, value } = event.target;
    const val = value.replace(/\D/g, '');
    const cleanValue = val.slice(0, 10);
    setData({
      ...data,
      [name]: cleanValue,
    });
  };

  const convertPhoneNumber = (number) =>{
    if(number){
      const cleanNumber = number.replace(/-/g, '');
      const formattedNumber = cleanNumber.replace(/(\d{3})(\d{3})(\d{4})/, (match, p1, p2, p3) => {
        if (p3) return `${p1}-${p2}-${p3}`;
        if (p2) return `${p1}-${p2}`;
        return p1;
      });
      return formattedNumber;
    }
      return '';
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(isEdit){
      await dispatch(updateCenterUser(id,data));
      navigate("/dashboard/center-user");
    }
    else{
      await dispatch(addCenterUser(data));
      navigate("/dashboard/center-user");
    }
  };

  const handleDelete = () => {
    dispatch(deleteCenterUser(id));
    navigate("/dashboard/center-user");
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="firstName"
            variant="outlined"
            placeholder="First Name"
            value={data.firstName}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalHospitalTwoTone />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="lastName"
            variant="outlined"
            placeholder="Last Name"
            value={data.lastName}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalHospitalTwoTone />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="email"
            variant="outlined"
            placeholder="Email"
            value={data.email}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalHospitalTwoTone />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="address1"
            variant="outlined"
            placeholder="Address 1"
            value={data.address1}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="address2"
            placeholder="Address 2"
            variant="outlined"
            value={data.address2}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
            
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="city"
            placeholder="City"
            variant="outlined"
            value={data.city}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCity />
                </InputAdornment>
              ),
              inputProps: { inputMode: 'text', pattern: '[A-Za-z]*' }
            }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="state"
            placeholder="State"
            variant="outlined"
            value={data.state}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCityTwoTone />
                </InputAdornment>
              ),
              inputProps: { inputMode: 'text', pattern: '[A-Za-z]*' }
            }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="zip"
            placeholder="ZIP"
            variant="outlined"
            value={data.zip}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FolderZip />
                </InputAdornment>
              ),
              inputProps: { inputMode: 'numeric', pattern: '[0-9]*' }
            }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="phoneNumber"
            placeholder="Phone"
            variant="outlined"
            value={convertPhoneNumber(data.phoneNumber)}
            onChange={handleChangeNumber}
            
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="dateOfBirth"
            label="Date of Birth"
            type="date"
            onChange={handleChange}
            value={data.dateOfBirth ? data.dateOfBirth.split("T")[0] : data.dateOfBirth}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </Grid>
      </Grid>

      <Row className="mt-5 align-items-center">
        <Col className='text-start'>
          <Link to={"/dashboard/center-user"}>
            <Button
              type="button"
              variant="contained"
              className="me-3 btn btn-outline-secondary"
              style={{
                padding: '13px 25px',
              }}>
              Back
            </Button>
          </Link>
        </Col>
        <Col className="text-end">
          <Button
            type="button"
            onClick={() => setModalShow(true)}
            variant="contained"
            className="me-3 btn btn-outline-danger"
            style={{
              padding: '13px 25px',
            }}
          >
            Delete
          </Button>
          <Button
            type="submit"
            variant="contained"
            style={{
              background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            }}
          >
            {isEdit ? 'Save':'Add'}
          </Button>
          <Button
            type="reset"
            variant="contained"
            className="bg-secondary ms-3"
            style={{
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
      {/* <Button type="submit" variant="contained" color="primary">
        Submit
      </Button> */}
      <DeleteModal
        show={modalShow}
        dltModalTxt={'Are you sure you want to delete this patient?'}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
    </form>
  );
}

export default AddCenterUserForm;
