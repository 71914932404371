import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/interceptor';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    organizationList: [],
    surveyList: [],
    survey: {},
    meta : {}
  };


  const slice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      shareSurveySuccess(state){
        state.isLoading = false;
      },

      getSurveySuccess(state, action){
        state.isLoading = false;
        state.surveyList = action.payload;
      },

      getSurveyByIdSuccess(state, action){
        state.isLoading = false;
        state.survey = action.payload;
      },

      getOrganizationSuccess(state, action){
        state.isLoading = false;
        state.organizationList = action.payload;
      },

      getMeta(state, action){
        state.meta = action.payload;
      },

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  export function publishSurvey(data){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/saas/publish-survey', data);
        dispatch(slice.actions.shareSurveySuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };
  
  export function unPublishSurvey(data){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/saas/unpublish-survey', data);
        dispatch(slice.actions.shareSurveySuccess(response.data));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  // export function getPublishedSurveyOrganization(id){
  //   return async () => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       const response = await axios.get(`/saas/get-published-survey/?surveyId=${id}`);
  //       dispatch(slice.actions.getOrganizationSuccess(response.data.data.items));
  //       dispatch(slice.actions.getMeta(response.data.data.meta));
  //       return { payload: {} };
  //     } catch (error) {
  //       console.log('error');
  //       dispatch(slice.actions.hasError(error));
  //       return { payload: { error: 'Error' } };
  //     }
  //   };
  // }

  export function getPublishedSurveyOrganization(id,page,rows){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/saas/get-published-survey/?surveyId=${id}&page=${page}&take=${rows}`);
        dispatch(slice.actions.getOrganizationSuccess(response.data.data.items));
        dispatch(slice.actions.getMeta(response.data.data.meta));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function saveSurvey(surveyJson){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.post(`/saas/create-surveyJS`, surveyJson);
        dispatch(slice.actions.shareSurveySuccess());
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };

  // export function getSurveyById(surveyId){
  //   return async () => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       const response = await axios.get(`/saas/get-surveyJS?surveyId=${surveyId}`);
  //       dispatch(slice.actions.getSurveyByIdSuccess(response.data.data.items[0]));
  //       dispatch(slice.actions.getMeta(response.data.data.meta));
  //       return { payload: {} };
  //     } catch (error) {
  //       console.log('error');
  //       dispatch(slice.actions.hasError(error));
  //       return { payload: { error: 'Error' } };
  //     }
  //   };
  // };

  export function getSurveyById(surveyId,page,rows){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/saas/get-surveyJS?surveyId=${surveyId}&page=${page || 1}&take=${rows || 10}`);
        dispatch(slice.actions.getSurveyByIdSuccess(response.data.data.items[0]));
        dispatch(slice.actions.getMeta(response.data.data.meta));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  };
  
  export function updateSurveySettings(surveyId, surveySeetings){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.put(`/saas/update-surveyJS/${surveyId}`, surveySeetings);
        dispatch(slice.actions.shareSurveySuccess());
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };  
  }

  export function getAllSurveySettings(){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/saas/get-surveyJS`);
        dispatch(slice.actions.getSurveySuccess(response.data.data.items));
        dispatch(slice.actions.getMeta(response.data.data.meta));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    }
  };

  export function getDirectorAllSurvey(){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/director/survey`);
        dispatch(slice.actions.getSurveySuccess(response.data.data.items));
        dispatch(slice.actions.getMeta(response.data.data.meta));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    }
  };

  export function getDirectorSurveyById(id){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/director/survey`);
        dispatch(slice.actions.getSurveyByIdSuccess(response.data.data.items.find(x=> x._id === id)));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    }
  };

  export function getPaAllSurvey(){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/program-admin/organization-survey`);
        dispatch(slice.actions.getSurveySuccess(response.data.data.items));
        dispatch(slice.actions.getMeta(response.data.data.meta));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    }
  };

  export function getPaSurveyById(id){
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/program-admin/organization-survey`);
        dispatch(slice.actions.getSurveyByIdSuccess(response.data.data.items.find(x=> x._id === id)));
        return { payload: {} };
      } catch (error) {
        console.log('error');
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    }
  };

export function deleteSurveyById(surveyId){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/saas/delete-survey?surveyId=${surveyId}`);
      dispatch(slice.actions.shareSurveySuccess());
      return { payload: {} };
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
      return { payload: { error: 'Error' } };
    }
  }
};

export function duplicateSurveyById(surveyId){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/saas/duplicate-surveyJS/${surveyId}`);
      dispatch(slice.actions.shareSurveySuccess());
      return { payload: {} };
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
      return { payload: { error: 'Error' } };
    }
  }
};

export function getDirectorPublishedSurvey(id,page,rows){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/director/survey?surveyId=${id}&page=${page || 1}&take=${rows || 10}`);
      dispatch(slice.actions.getOrganizationSuccess(response.data.data.items));
      dispatch(slice.actions.getMeta(response.data.data.meta));
      return { payload: {} };
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
      return { payload: { error: 'Error' } };
    }
  };
}

export function publishSurveyDirector(data){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/director/share-survey', data);
      dispatch(slice.actions.shareSurveySuccess(response.data));  
      return { payload: {} };
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
      return { payload: { error: 'Error' } };
    }
  };
};

export function unPublishSurveyDirector(data){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/director/unshare-survey', data);
      dispatch(slice.actions.shareSurveySuccess(response.data));
      return { payload: {} };
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
      return { payload: { error: 'Error' } };
    }
  };
};