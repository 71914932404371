import { useState } from 'react';
import { Col, Container, Row, Card, Modal, Form, Tabs, Tab } from 'react-bootstrap';
import { FaCopy, FaEnvelope } from 'react-icons/fa';
import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';
import CodeIcon from '@mui/icons-material/Code';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import ReactStars from 'react-rating-stars-component';
import MyRadioButtons from '../form/TabsRadio';
import PublishModal from '../modal/PublishModal';

const PublishPreviewTab = () => {
  const [show, setShow] = useState(false);
  const [openPulishModal, setOpenPulishModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClosePublishModal = () => setOpenPulishModal(false);
  const handleOpenPublishModal = () => setOpenPulishModal(true);

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  return (
    <div>
      <Container>
        <Row className=" mt-4">
          <Col>
            <Card className=" shadow-sm px-5 py-4" border="0">
              <Row>
                <Col xs={8}>
                  <div className="d-flex align-items-center mb-2">
                    <h4 className="text-secondary" style={{ marginBottom: '0' }}>
                      Survey - Patient Admission Assesment
                    </h4>
                    <span
                      className="mx-2"
                      style={{
                        backgroundColor: '#ffa5003d',
                        fontSize: '12px',
                        padding: '0 5px',
                        color: 'orange',
                        borderRadius: '25px',
                      }}
                    >
                      draft
                    </span>
                  </div>
                </Col>
                <Col className="text-end" xs={4}>
                  <Button
                    className="btn btn-primary me-3"
                    onClick={handleShow}
                    style={{
                      background: '#6A6A6A',
                      border: 'none',
                      borderRadius: '6px',
                      fontSize: '0.875rem',
                      color: '#fff',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                    }}
                  >
                    Share
                  </Button>
                  <Button
                    className="btn btn-primary"
                    onClick={handleOpenPublishModal}
                    style={{
                      background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                      border: 'none',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                      fontSize: '13px',
                    }}
                  >
                    Publish
                  </Button>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={12} className="mb-4">
                  <p className="text-secondary" style={{ fontWeight: '500', fontSize: '14px' }}>
                    What is your name?
                  </p>
                  <Row>
                    <Col md={6}>
                      <input type="text" style={{ width: '100%', borderRadius: '4px', padding: '5px' }} />
                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="mb-4">
                  <p className="text-secondary" style={{ fontWeight: '500', fontSize: '14px' }}>
                    Tell a bit about yourself
                  </p>
                  <Row>
                    <Col md={6}>
                      <textarea
                        name=""
                        style={{ width: '100%', borderRadius: '4px', padding: '5px', minHeight: '100px' }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="mb-4">
                  <p className="text-secondary" style={{ fontWeight: '500', fontSize: '14px' }}>
                    How often do you drink?
                  </p>
                  <MyRadioButtons label1="Once in a week" label2="on the daily basis" label3="twice a day" />
                </Col>
                <Col md={12} className="mb-4">
                  <Table className="surveyTable">
                    <thead>
                      <tr>
                        <th>Generalized Anxiety Disorder Screener (GAD-7)</th>
                        <th>Nearly everyday</th>
                        <th>Several day</th>
                        <th>More than half the days</th>
                        <th>Not at all</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1. Feeling anxious, nervous or edge.</td>
                        <td>0</td>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                      </tr>
                      <tr>
                        <td>1. Feeling anxious, nervous or edge.</td>
                        <td>0</td>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                      </tr>
                      <tr>
                        <td>1. Feeling anxious, nervous or edge.</td>
                        <td>0</td>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                      </tr>
                      <tr>
                        <td>1. Feeling anxious, nervous or edge.</td>
                        <td>0</td>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={12} className="mb-4">
                  <p className="text-secondary" style={{ fontWeight: '500', fontSize: '14px' }}>
                    Is it true alchohol causes laziness?
                  </p>
                  <MyRadioButtons label1="True" label2="False" hiddenClass={'hiddenClass'} />
                </Col>
                <Col md={12} className="mb-4">
                  <p className="text-secondary" style={{ fontWeight: '500', fontSize: '14px' }}>
                    Select the right answer choice
                  </p>
                  <Row>
                    <Col xs={3}>
                      <Form.Group className="mb-3">
                        <Form.Select>
                          <option>Lazy</option>
                          <option>Quick</option>
                          <option>Smart</option>
                          <option>Weak</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="mb-4">
                  <p className="text-secondary" style={{ fontWeight: '500', fontSize: '14px' }}>
                    Rate your experience with us
                  </p>
                  <ReactStars count={5} onChange={ratingChanged} size={24} activeColor="#ffd700" />,
                </Col>
                <Col md={12} className="mb-4">
                  <p className="text-secondary" style={{ fontWeight: '500', fontSize: '14px' }}>
                    Check all the possibe answers
                  </p>
                  <div className="surveyCheckbox">
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      label=" once in a week"
                      className="mb-3"
                    />
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      label="on the daily basis"
                      className="mb-3"
                    />
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      label="twice a day"
                      className="mb-3"
                    />
                  </div>
                </Col>
                <Col md={12} className="mb-4">
                  <p className="text-secondary" style={{ fontWeight: '500', fontSize: '14px' }}>
                    Date/Time
                  </p>
                  <div className="form-check p-0 suveryDateInput">
                    <div className="formInput">
                      <span>Date</span>
                      <input type="date" />
                    </div>
                    <div className="formInput">
                      <span>Time</span>
                      <input type="time" />
                    </div>
                    <div className="formInput">
                      <span>AM/PM</span>
                      <Form.Group>
                        <Form.Select>
                          <option>AM</option>
                          <option>PM</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                </Col>
                <Col md={12} className="mb-4">
                  <p className="text-secondary" style={{ fontWeight: '500', fontSize: '14px' }}>
                    Upload file
                  </p>
                  <input type="file" />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Modal
          className="mt-3 p-0 shareModal"
          style={{ zIndex: '9999999', marginTop: '300px' }}
          show={show}
          onHide={handleClose}
        >
          <Modal.Body className="pt-5">
            <div className="d-flex px-2 justify-content-between">
              <h4 className="text-secondary">Share Survey</h4>

              <Button
                variant="primary"
                onClick={handleClose}
                className="rounded-circle"
                style={{
                  background: 'white',
                  color: '#1D72E8',
                  border: '1px solid #1D72E8',
                  height: '30px',
                  width: '30px',
                  bottom: '10px',
                  padding: '0px',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                }}
              >
                {' '}
                x
              </Button>
            </div>
            <Row>
              <Col md={12}>
                <div className="formTabs mt-3">
                  <Tabs
                    defaultActiveKey="clinicForm"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3 mx-3 bg-white border-muted tab surveryModalTab"
                    style={{
                      borderTop: '1px solid #ccc',
                      borderLeft: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    <Tab
                      eventKey="clinicForm"
                      title={
                        <span>
                          <EmailIcon />
                        </span>
                      }
                    >
                      <div className="surveryTabWrp">
                        <Row>
                          <Col md={12}>
                            <form action="">
                              <p>Email</p>
                              <input type="text" />
                            </form>
                          </Col>
                          <Col md={12}>
                            <form action="">
                              <textarea name="" placeholder="Write invitation message (optional)" />
                            </form>
                          </Col>
                          <Col md={12}>
                            <div className="text-end mt-4">
                              <button
                                type="button"
                                className="btn btn-outline-secondary me-3"
                                to="/dashboard/ClinicList"
                                style={{
                                  boxShadow:
                                    'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                                  padding: '13px 25px',
                                  fontSize: '18px',
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                style={{
                                  background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                                  padding: '13px 25px',
                                  border: 'none',
                                  fontSize: '18px',
                                  color: '#fff',
                                  boxShadow:
                                    'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                                }}
                                className="btn"
                              >
                                Send
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="directorForm"
                      title={
                        <span>
                          <LinkIcon />
                        </span>
                      }
                    >
                      <div className="surveryTabWrp">
                        <form action="">
                          <Row>
                            <Col md={12}>
                              <p>Link</p>
                              <span>
                                https://www.google.com/search q=Copy+Survey+Link&rlz1C1CHBD_enPK1036PK103 6&oq...
                              </span>
                            </Col>
                            <Col md={12}>
                              <div className="text-end mt-4">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary me-3"
                                  to="/dashboard/ClinicList"
                                  style={{
                                    boxShadow:
                                      'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                                    padding: '13px 25px',
                                    fontSize: '18px',
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  style={{
                                    background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                                    padding: '13px 25px',
                                    border: 'none',
                                    fontSize: '18px',
                                    color: '#fff',
                                    boxShadow:
                                      'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                                  }}
                                  className="btn"
                                >
                                  Copy
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="thirdTab"
                      title={
                        <span>
                          <CodeIcon />
                        </span>
                      }
                    >
                      <div className="surveryTabWrp">
                        <form action="">
                          <Row>
                            <Col md={12}>
                              <p>Embed HTML</p>
                            </Col>
                            <Col md={12}>
                              <div className="text-end mt-4">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary me-3"
                                  to="/dashboard/ClinicList"
                                  style={{
                                    boxShadow:
                                      'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                                    padding: '13px 25px',
                                    fontSize: '18px',
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  style={{
                                    background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                                    padding: '13px 25px',
                                    border: 'none',
                                    fontSize: '18px',
                                    color: '#fff',
                                    boxShadow:
                                      'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                                  }}
                                  className="btn"
                                >
                                  Copy
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
      <PublishModal show={openPulishModal} onHide={handleClosePublishModal} />
    </div>
  );
};

export default PublishPreviewTab;
