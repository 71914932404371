import React from 'react';
import { Col, Container, Row, Card, Button, Table } from 'react-bootstrap';
import DownloadIcon from '@mui/icons-material/Download';
import { FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AnswerModal from '../modal/AnswerModal';

const AnswerTab = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [openResetModal, setOpenResetModal] = React.useState(false);

  return (
    <div>
      <Container>
        <Row className=" mt-4">
          <Col>
            <Card className=" shadow-sm px-5 py-4" border="0">
              <Row>
                <Col xs={8}>
                  <div className="mb-2">
                    <h4 className="text-secondary" style={{ marginBottom: '0', color: '#505050' }}>
                      Answers
                    </h4>
                    <p>
                      Total number of times taken: <span style={{ fontWeight: '600' }}>4</span>
                    </p>
                  </div>
                </Col>
                <Col className="text-end" xs={4}>
                  <Button
                    className="btn btn-primary me-3"
                    style={{
                      background: '#6A6A6A',
                      border: 'none',
                      borderRadius: '6px',
                      fontSize: '0.875rem',
                      color: '#fff',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                    }}
                  >
                    <DownloadIcon /> Download
                  </Button>
                </Col>
                <Col xs={12}>
                  <div className="answerTableWrp">
                    <Table striped="rows" className="text-center">
                      <thead>
                        <tr>
                          <th>Date Taken</th>
                          <th>Answers</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>MM-DD-YYY 19:30</td>
                          <td>
                            <button
                              type="button"
                              onClick={() => setOpenResetModal(true)}
                              style={{ color: '#1A73E8' }}
                            >
                              View Answers
                            </button>
                          </td>
                          <td>
                            <button type="button">
                              {' '}
                              <FaTrash
                                type="button"
                                className="text-light p-2 rounded-circle bg-secondary ms-3"
                                style={{ width: '29px', height: '29px' }}
                              />{' '}
                              &nbsp; Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      <AnswerModal show={openResetModal} onHide={() => setOpenResetModal(false)} />
    </div>
  );
};

export default AnswerTab;
