import React, { useState } from 'react';
import { Col, Container, Card, Form, Row, Button } from 'react-bootstrap';
import { FaMinusCircle, FaPlusCircle, FaQuestionCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SkipLogicMultipleQuestion = () => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <Container>
        <Card className="px-2 pb-3 shadow-sm" border="0" style={{ background: '#F6F7F9' }}>
          <Row className="mt-4 justify-content-between">
            <Col sm={2} className="">
              <div className="pe-3">
                <input
                  type="text"
                  className="text-center pe-3"
                  value="Order"
                  id="textInput"
                  style={{ height: '40px', border: '1px solid #ccc' }}
                />
              </div>
            </Col>
            <Col sm={6} className="pe-0">
              <input
                type="text"
                className="text-center w-100"
                value="Enter your Question"
                id="textInput"
                style={{ height: '40px', width: '100%', border: '1px solid #ccc' }}
              />
            </Col>
            <Col sm={4} className="ps-0">
              <select className="w-100 ms-0" style={{ height: '40px', border: '1px solid #ccc' }}>
                {/* <option value="">Short Text</option>
                            <option value="option">Option 1</option>
                            <option value="option1">Numeric - discrete</option>
                            <option value="option2">Numeric - continuous</option>
                            <option value="option3">Generalised Anxiety Disorder - 7</option>
                            <option value="option4">Patient Health Questionair - 9</option>
                            <option value="option5">Email</option>
                            <option value="option6">Date</option>
                            <option value="option7">Drop Down</option> */}
              </select>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col md={6}>
            <Form className="radio-tabs">
              <div className="d-flex align-items-center justify-content-between mt-4">
                <small className="text-secondary ms-5">
                  If Answer is _ <FaQuestionCircle />
                </small>
                <small className="text-secondary ps-0">
                  Then skip to _ <FaQuestionCircle />
                </small>
              </div>
              <div className="d-flex align-items-center mt-4 justify-content-between">
                <Form.Check
                  type="radio"
                  name="frequency"
                  checked={selectedOption === 'onceAWeek'}
                  onChange={handleRadioChange}
                />
                <input
                  type="text"
                  className="ps-4 py-3 w-100 me-5"
                  placeholder="None"
                  id="textInput"
                  style={{ height: '30px', width: '100%', border: '1px solid #F3F3F3' }}
                />

                <Form.Select
                  aria-label=""
                  style={{ height: '35px', width: '100%', border: '1px solid #F3F3F3', borderRadius: '0px' }}
                >
                  <option className="text-secondary">Question 4</option>
                  <option>
                    <span> Question 2 &nbsp;&nbsp; Do you want a batter future?</span>
                  </option>
                  <option>
                    <span> Question 3 &nbsp;&nbsp; Write some articles to achieve the best health</span>
                  </option>
                  <option>
                    <span>Question 4 &nbsp;&nbsp; How often do you take a drugs</span> <span>d</span>{' '}
                  </option>
                  <option>
                    <span>Question 5 &nbsp;&nbsp; Have you ever been dmited to a rehabilitaion center</span>
                  </option>
                </Form.Select>
              </div>

              <div className="d-flex align-items-center mt-4 justify-content-between">
                <Form.Check
                  type="radio"
                  name="frequency"
                  checked={selectedOption === 'onceAWeek'}
                  onChange={handleRadioChange}
                />
                <input
                  type="text"
                  className="ps-4 py-3 w-100 me-5"
                  placeholder="2-3 Days"
                  id="textInput"
                  style={{ height: '30px', width: '100%', border: '1px solid #F3F3F3' }}
                />

                <Form.Select
                  aria-label=""
                  style={{ height: '35px', width: '100%', border: '1px solid #F3F3F3', borderRadius: '0px' }}
                >
                  <option className="text-secondary">Question 2</option>
                  <option>
                    <span> Question 2 &nbsp;&nbsp; Do you want a batter future?</span>
                  </option>
                  <option>
                    <span> Question 3 &nbsp;&nbsp; Write some articles to achieve the best health</span>
                  </option>
                  <option>
                    <span>Question 4 &nbsp;&nbsp; How often do you take a drugs</span> <span>d</span>{' '}
                  </option>
                  <option>
                    <span>Question 5 &nbsp;&nbsp; Have you ever been dmited to a rehabilitaion center</span>
                  </option>
                </Form.Select>
              </div>

              <div className="d-flex align-items-center mt-4 justify-content-between">
                <Form.Check
                  type="radio"
                  name="frequency"
                  checked={selectedOption === 'onceAWeek'}
                  onChange={handleRadioChange}
                />
                <input
                  type="text"
                  className="ps-4 py-3 w-100 me-5"
                  placeholder="4-5 Days"
                  id="textInput"
                  style={{ height: '30px', width: '100%', border: '1px solid #F3F3F3' }}
                />

                <Form.Select
                  aria-label=""
                  style={{ height: '35px', width: '100%', border: '1px solid #F3F3F3', borderRadius: '0px' }}
                >
                  <option className="text-secondary">Question 2</option>
                  <option>
                    <span> Question 2 &nbsp;&nbsp; Do you want a batter future?</span>
                  </option>
                  <option>
                    <span> Question 3 &nbsp;&nbsp; Write some articles to achieve the best health</span>
                  </option>
                  <option>
                    <span>Question 4 &nbsp;&nbsp; How often do you take a drugs</span> <span>d</span>{' '}
                  </option>
                  <option>
                    <span>Question 5 &nbsp;&nbsp; Have you ever been dmited to a rehabilitaion center</span>
                  </option>
                </Form.Select>
              </div>
            </Form>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="text-end">
            <Link
              className="btn btn-outline-secondary me-3"
              to="/"
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                padding: '5px 25px',
                fontSize: '13px',
              }}
            >
              Cancel
            </Link>
            <Button
              type="submit" variant="contained" className='text-light' style=
              {{
                background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                padding: '5px 25px',
                border: 'none',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
              }}
              >Update
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SkipLogicMultipleQuestion;
