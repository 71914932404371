import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Card, Modal, Button, Badge } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FaPen, FaPowerOff, FaTrash, FaUser } from 'react-icons/fa';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import DeleteModal from '../modal/DeleteModal';
import { useDispatch, useSelector } from '../../redux/store';
import { deactivateOrganization, getOrganizationList, getOrganizationListByFilter, removeOrganization } from '../../redux/slices/organization';

const OrganizationTable = ({search, status, licenseType}) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [organizationRow,setOrganizationRow] = React.useState([]);

  const [selectedId,setSelectedId] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organizationList, organizationMeta } = useSelector((state)=> state.organization);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if(status || licenseType){
      dispatch(getOrganizationListByFilter(status,licenseType,page+1,rowsPerPage));
    }
    else{
      dispatch(getOrganizationList(page+1,rowsPerPage));
    }
  }, [dispatch,status,licenseType,page,rowsPerPage]);

  useEffect(()=>{
    console.log(organizationList);
    setOrganizationRow(organizationList || []);
  },[organizationList])

  useEffect(()=>{
    if(organizationMeta){
      setTotalRows(organizationMeta.totalItems);
      setRowsPerPage(organizationMeta.itemsPerPage);
    }
  },[organizationMeta])

  useEffect(() => {
    if(search !== null && search !== ''){
      setOrganizationRow(organizationList.filter(x=>x.name.toLowerCase().includes(search.toLowerCase())));
    }
    else{
      setOrganizationRow(organizationList);
    }
  },[search]);

  const removeOrg= async (id)=> {
    await dispatch(removeOrganization(id));
    setSelectedId('');
    await dispatch(getOrganizationList());
    setOpenDeleteModal(false);
  }

  const deactivateOrg = async (id,status) => {
    await dispatch(deactivateOrganization(id,status));
    await dispatch(getOrganizationList());
  }


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = [...organizationRow].sort((a, b) => {
    if (orderBy) {
      return order === 'asc'
        ? a[orderBy] < b[orderBy] ? -1 : 1
        : a[orderBy] > b[orderBy] ? -1 : 1;
    }
    return 0;
  });

  return (
    <>
    <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={() => handleRequestSort('name')}
                      >
                      Organization
                    </TableSortLabel>
                  </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'type'}
                        direction={orderBy === 'type' ? order : 'asc'}
                        onClick={() => handleRequestSort('type')}
                      >
                        License Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'location'}
                        direction={orderBy === 'location' ? order : 'asc'}
                        onClick={() => handleRequestSort('location')}
                      >
                        Location
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'status'}
                        direction={orderBy === 'status' ? order : 'asc'}
                        onClick={() => handleRequestSort('status')}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={{textAlign:'center'}}>
                        Actions
                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData && sortedData.map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {row.type === 'admin' ? "Program Admin" : row.type}
                    </TableCell>
                    <TableCell>
                      { row.address ? <>{row.address.address1 || ""},
                      <br /> {row.address.city || ""}, {row.address.zip || ""}</>: ""}
                    </TableCell>
                    <TableCell>
                      { row.status ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell>
                      <Link to={`/dashboard/edit-organization/${row.id}`} style={{ textDecoration: 'none', color: '#000' }}>
                        <div
                        style={{
                          background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                          padding: '0px 5px 2px',
                          borderRadius: '50%',
                          textAlign: 'center',
                          float: 'left',
                        }}>
                          <FaPen style={{fill: '#fff', fontSize: '11px'}}/>
                        </div>
                        &nbsp; Manage
                      </Link>
                      <button
                        type="button"
                        onClick={() => deactivateOrg(row.id,!row.status)}
                        style={{ border: 'none', backgroundColor: 'transparent', padding: '0' }}
                      >
                        <FaPowerOff
                          type="button"
                          className="text-light p-2 rounded-circle bg-danger ms-3"
                          style={{ width: '29px', height: '29px' }}
                        />
                        &nbsp; {row.status ? 'Deactivate' : 'Activate'}
                      </button>
                      <button
                        type="button"
                        onClick={() => {setSelectedId(row.id);setOpenDeleteModal(true);}}
                        style={{ border: 'none', backgroundColor: 'transparent', padding: '0' }}
                      >
                        <FaTrash
                          type="button"
                          className="text-light p-2 rounded-circle bg-secondary ms-3"
                          style={{ width: '29px', height: '29px' }}
                        />
                        &nbsp; Delete
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          
          <DeleteModal dltModalTxt={"Are you sure you want to delete the organization"} onDelete={()=> removeOrg(selectedId)} show={openDeleteModal} onHide={() => {setOpenDeleteModal(false); setSelectedId('');}} />
    </Paper>
    </>
  );
};

export default OrganizationTable;
