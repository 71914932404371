import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import organizationReducer from './slices/organization';
import surveyReducer from './slices/survey';
import resetPasswordReducer from './slices/resetPassword';
import userReducer from './slices/user';
import userPermissionReducer from './slices/userPermission';
import programAdminReducer from './slices/programAdmin';
import loaderReducer from './slices/loader';
import centerUserReducer from './slices/centerUser';
import surveyStatsReducer from './slices/surveyStats';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  organization: organizationReducer,
  survey: surveyReducer,
  resetPassword: resetPasswordReducer,
  user: userReducer,
  userPermission: userPermissionReducer,
  programAdmin: programAdminReducer,
  loader: loaderReducer,
  centerUser: centerUserReducer,
  surveyStats: surveyStatsReducer,
});

export { rootPersistConfig, rootReducer };