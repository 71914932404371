
import api from './interceptor';

export const saveSurvey = async (surveyJson) => {
  try {
    const response = await api.post(`/saas/create-surveyJS`, surveyJson);
    return response.data.data;
  } catch (error) {
    console.error("Error saving survey:", error);
    throw error;
  }
};
export const getSurveyById = async (surveyId) => {
  try {
    const response = await api.get(`/saas/get-surveyJS?surveyId=${surveyId}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { data: null, error: 'Object not found' };
    } 
    return { data: null, error: 'An error occurred' };

  }
};

export const updateSurveySettings = async (surveyId, surveySeetings) => {
  try {
    const response = await api.put(`/saas/update-surveyJS/${surveyId}`, surveySeetings);
    return response.data;
  } catch (error) {
    console.error("Error getting survey settings:", error);
    // throw error;
    return error.response.data;
  }

}

export const getAllSurveySettings = async () => {
  try {
    const response = await api.get('/saas/get-surveyJS');
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { data: null, error: 'Object not found' };
    } 
    return { data: null, error: 'An error occurred' };
  }
};

export const deleteSurveyById = async (surveyId) => {
  try {
    const response = await api.delete(`/saas/delete-survey?surveyId=${surveyId}`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { data: null, error: 'Object not found' };
    } 
    return { data: null, error: 'An error occurred' };

  }
};

export const duplicateSurveyById = async (surveyId) => {
  try {
    const response = await api.post(`/saas/duplicate-surveyJS/${surveyId}`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { data: null, error: 'Object not found' };
    } 
    return { data: null, error: 'An error occurred' };

  }
};