import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Modal } from 'react-bootstrap';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CardMedia, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const PasswordChangeModal = (props) => {
  //   const [modalShow, setModalShow] = React.useState(false);

  return (
    <Modal
      {...props}
      size="sm"
      className="passwordChangeModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-4 text-center">
        <div className="text-center passwordModalWrp" style={{ width: '400px', margin: '0 auto' }}>
          <div className="checkMarkIcon">
            <CheckCircleOutlineIcon style={{ fontSize: '5rem' }} />
          </div>
          <div className="mainHeading mt-3">
            <h3 style={{ fontSize: '27px', color: '#363636' }}>Password Successfully Reset</h3>
          </div>
          <div className="statusWrp mt-4">
            <div className="left">
              <span>User:</span>
            </div>
            <div className="right">
              <span>Dr Kelly Brook</span>
            </div>
          </div>
          <div className="statusWrp">
            <div className="left">
              <span>Account Status:</span>
            </div>
            <div className="right">
              <span>Active</span>
            </div>
          </div>
          <div className="instructionWrp mt-4">
            <p>Important Instructions</p>
            <ul>
              <li>
                <span>
                  <ArrowForwardIcon />
                  New password has been successfully sent to the users registered email address.
                </span>
              </li>
              <li>
                <span>
                  <ArrowForwardIcon />
                  Advise the user to change their password immediately after the first login for enhanced security.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordChangeModal;
