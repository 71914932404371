import React, { useEffect, useState } from 'react';
import { Accordion, Card, Col, Container, Row } from 'react-bootstrap';
import { InputAdornment, TextField } from '@mui/material';
import { FaUser } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from '../../redux/store';
import { addCustomRole, getCustomRoleById, getPermissionList, updateCustomRole } from '../../redux/slices/userPermission';
import * as toaster from '../../utils/toaster';

const UserPermissions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();

  const isEdit = pathname.includes('UserPermissionsEdit');
  const { permissionList, customRole } = useSelector((state)=> state.userPermission);
  const [checkbox,setCheckbox] = useState([]);
  const [name, setName] = useState('');

  const handleChange = (e) => {
    setName(e.target.value);
  }

  const handleCheckboxChange = (e, item) => {
    if (e.target.checked) {
      setCheckbox((prev) => [...prev, { permissionId: item.id }]);
    } else {
      setCheckbox((prev) =>
        prev.filter((selectedItem) => selectedItem.permissionId !== item.id)
      );
    }
  };

  const handleSubmit = async () => {
    const data = {
      permissions: checkbox,
      name,
      description: "This is Description for Custom Role "
    }
    console.log("data",data);
    let res;
    if(isEdit){
      res = await dispatch(updateCustomRole(id,data));
    }
    else{
      res = await dispatch(addCustomRole(data));
    }

    if(res.status){
      toaster.success(`Role ${isEdit ? 'updated':'added'} successfully!`);
      navigate("/dashboard/user-permission");
    }
    else{
      toaster.error("error!");
    }
  }

  useEffect(()=>{
    dispatch(getPermissionList());
    if(isEdit){
      dispatch(getCustomRoleById(id));
    }
  },[dispatch,id,isEdit]);

  useEffect(()=>{
    if(isEdit && customRole && id){
      if(customRole?.permissions){
        setCheckbox(customRole?.permissions);
      }
      setName(customRole?.name);
    }
  },[isEdit,id,customRole]);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Card className="shadow-sm px-3 py-5" border="0">
              <h3 className="text-secondary mb-3">User Role</h3>
              <Row>
                <TextField
                  fullWidth
                  name="name"
                  value={name}
                  variant="outlined"
                  placeholder="Custom Role Name"
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaUser />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Row>
              <Row>
                {permissionList && permissionList.map(permission=>(
                  <Col key={permission.id} md={4} className="pe-5">
                    <Accordion defaultActiveKey="0" style={{border:'0'}} >
                      <Accordion.Item eventKey="0" style={{border:'0'}}>
                        <Accordion.Header><h5 className='text-muted'>{permission.name}</h5></Accordion.Header>
                        {permission.permissions && permission.permissions.map(value=>(
                          <Accordion.Body key={value.id}>
                            <div>
                              <div className='d-flex align-items-start text-muted'>
                                <input
                                  type="checkbox"
                                  className='mt-2 me-3'
                                  name={value.name}
                                  checked={checkbox.some((item) => item.permissionId === value.id)}
                                  onChange={(e)=>handleCheckboxChange(e,value)}
                                />
                                <p className='mt-0'>{value.name}</p>
                              </div>
                            </div>
                        </Accordion.Body>
                        ))}
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col>
                  <button
                    className="btn btn-outline-secondary me-3"
                    type='button'
                    style={{
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                      padding: '13px 25px',
                      fontSize: '13px',
                    }}
                  >
                    Cancel
                  </button>
                </Col>
                <Col>
                  <div className='text-end'>
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className='btn'
                        style={{
                          background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                          padding: '13px 25px',
                          border: 'none',
                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                          color: '#fff'
                        }}
                      >
                        Save
                      </button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        
      </Container>
    </div>
  );
};

export default UserPermissions;
